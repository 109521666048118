import React, { PureComponent } from 'react';
import { SurveyDetail } from '../entityconfig';
import BaseView from './BaseView';

class SurveyDetailView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(SurveyDetail);
  }
}

export default SurveyDetailView;
