import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';
import './Test2.css';

const Test2 = () => {
  const text = 'Share your requirement';
  const buttonText = 'CONTACT US';
  const cardHeader = 'MOST POPULAR';

  const clientData = [
    {
      name: 'STANDARD',
      price: 'US$',
      priceDigit: 8,
      pathText: '/user/month billed annually',
      buttonText: 'START CREATING',
      cardText: 'Create your first app',
    },
    {
      name: 'Professional',
      price: 'US$',
      priceDigit: 20,
      pathText: '/user/month billed annually',
      buttonText: 'START CREATING',
      cardText: 'Create apps for your team/development',
    },
    {
      name: 'ENTERPRISE',
      cardHeader,
      price: 'US$',
      priceDigit: 25,
      pathText: '/user/month billed annually',
      buttonText: 'START CREATING',
      cardText: 'Create core software solutions for your organization',
      backgroundColor: 'card-background-enterprise', 
    },
    {
      name: 'FLEX',
      data: text,
      buttonText,
      cardText: 'If you have custom requirements and need a tailored plan',
    },
  ];

  return (
    <div className="page-container">
      <Grid container>
        {clientData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={item.name} style={{ textAlign: 'center' }}>
            <Card
              className={`card-root ${item.backgroundColor || ''}
              
               ${ index === 0 ? 'first-card' : '' } 
               ${ index === 1 ? 'second-card' : ''} 
               ${ index === 2 ? 'third-card' : '' } 
               ${ index === 3 ? 'fourth-card' : ''}`}
            >
              {index === 2 && (
                <div className="popular-container">
                  <span>{item.cardHeader}</span>
                </div>
              )}
              <CardHeader
                title={<Typography variant="h6">{item.name}</Typography>}
                subheader={
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h6" className="card-title">
                      {item.price}
                    </Typography>
                    <Typography variant="body2" className="card-title1">
                      {item.data}
                    </Typography>
                    <Typography variant="h3" className="card-price">
                      {item.priceDigit}
                    </Typography>
                  </div>
                }
              />
              <CardContent style={{ marginTop: '-20px' }}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {item.pathText}
                </Typography>
                <br />
                {index === 0 || index === 1 ? (
                  <Button variant="outlined" color="secondary">
                    {item.buttonText}
                  </Button>
                ) : index === 2 ? (
                  <Button variant="contained" color="secondary">
                    {item.buttonText}
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" className="card-button-flex">
                    {item.buttonText}
                  </Button>
                )}
                <br />
                <br />
                <Typography variant="body1">{item.cardText}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Test2;
