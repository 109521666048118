import React, { PureComponent } from 'react';
import { Logs } from '../entityconfig';
import BaseView from './BaseView';

class LogsView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(Logs);
  }
}

export default LogsView;
