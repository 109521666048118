import { Actions } from '../core/redux-helper';

export const Videos = {
  key: 'Videos',
  title: 'Videos',
  idColumn: 'VideosId',
  listAPI: Actions.listVideos,
  getGridColumns: () => [
    {
      dataIndex: 'VideosId',
      header: 'VideosId',
    },
    {
      dataIndex: 'UserId',
      header: 'UserId',
      type: 'combo',
      comboType: 'Users',
    },
    {
      dataIndex: 'PlaceId',
      header: 'PlaceId',
      type: 'combo',
      comboType: 'Place',
    },
    {
      dataIndex: 'ThumbnailUrl',
      header: 'ThumbnailUrl',
    },
    {
      dataIndex: 'TimeStamp',
      header: 'TimeStamp',
      type: 'date',
    },
    {
      dataIndex: 'VideoURL',
      header: 'VideoURL',
    },
    {
      dataIndex: 'IsLocation',
      header: 'IsLocation',
      type: 'boolean',
    },
    {
      dataIndex: 'CityId',
      header: 'CityId',
      type: 'combo',
      comboType: 'City',
    },
    {
      dataIndex: 'ViewCount',
      header: 'ViewCount',
      type: 'number',
    },
    {
      dataIndex: 'VideoDescription',
      header: 'VideoDescription',
    },
    {
      dataIndex: 'TagLocation',
      header: 'TagLocation',
      type: 'boolean',
    },
    {
      dataIndex: 'LikeCount',
      header: 'LikeCount',
      type: 'number',
    },
    {
      dataIndex: 'DislikeCount',
      header: 'DislikeCount',
      type: 'number',
    },
    {
      dataIndex: 'Hashtag',
      header: 'Hashtag',
    },
    {
      dataIndex: 'Emoji',
      header: 'Emoji',
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'BatchName',
      header: 'BatchName',
    },
    {
      dataIndex: 'OldVideoURL',
      header: 'OldVideoURL',
    },
    {
      dataIndex: 'region',
      header: 'region',
    },
    {
      dataIndex: 'TestLatitude',
      header: 'TestLatitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'TestLongitude',
      header: 'TestLongitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Hashtag1',
      header: 'Hashtag1',
    },
    {
      dataIndex: 'Hashtag2',
      header: 'Hashtag2',
    },
    {
      dataIndex: 'Hashtag3',
      header: 'Hashtag3',
    },
    {
      dataIndex: 'Hashtag4',
      header: 'Hashtag4',
    },
    {
      dataIndex: 'Hashtag5',
      header: 'Hashtag5',
    },
    {
      dataIndex: 'MigratedDate',
      header: 'MigratedDate',
      type: 'date',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
    {
      dataIndex: 'ShortCode',
      header: 'ShortCode',
    },
    {
      dataIndex: 'PlaceName',
      header: 'PlaceName',
    },
    {
      dataIndex: 'MigratedVideoURL',
      header: 'MigratedVideoURL',
    },
    {
      dataIndex: 'Date',
      header: 'Date',
      type: 'date',
    },
    {
      dataIndex: 'IsNeeded',
      header: 'IsNeeded',
      type: 'boolean',
    },
    {
      dataIndex: 'IsAvailable',
      header: 'IsAvailable',
      type: 'boolean',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'UserId',
      header: 'UserId',
      type: 'combo',
      comboType: 'Users',
    },
    {
      dataIndex: 'PlaceId',
      header: 'PlaceId',
      type: 'combo',
      comboType: 'Place',
    },
    {
      dataIndex: 'IsLocation',
      header: 'IsLocation',
      type: 'boolean',
      isRequired: true,
    },
    {
      dataIndex: 'CityId',
      header: 'CityId',
      type: 'combo',
      comboType: 'City',
    },
    {
      dataIndex: 'ViewCount',
      header: 'ViewCount',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'VideoDescription',
      header: 'VideoDescription',
      isRequired: true,
      colSpan: 12,
    },
    {
      dataIndex: 'VideoURL',
      header: 'VideoURL',
      type: 'videoUpload',
      colSpan: 12,
      rowSpan: 13,
    },
    {
      dataIndex: 'ThumbnailUrl',
      header: 'ThumbnailUrl',
      type: 'imageUpload',
      colSpan: 12,
      rowSpan: 13,
      height: 225,
      width: '100%',
    },
    {
      dataIndex: 'TagLocation',
      header: 'TagLocation',
      type: 'boolean',
      isRequired: true,
    },
    {
      dataIndex: 'LikeCount',
      header: 'LikeCount',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'DislikeCount',
      header: 'DislikeCount',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'Hashtag',
      header: 'Hashtag',
    },
    {
      dataIndex: 'Emoji',
      header: 'Emoji',
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'float',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'float',
    },
    {
      dataIndex: 'BatchName',
      header: 'BatchName',
    },
    {
      dataIndex: 'OldVideoURL',
      header: 'OldVideoURL',
    },
    {
      dataIndex: 'region',
      header: 'region',
    },
    {
      dataIndex: 'TestLatitude',
      header: 'TestLatitude',
      type: 'float',
    },
    {
      dataIndex: 'TestLongitude',
      header: 'TestLongitude',
      type: 'float',
    },
    {
      dataIndex: 'Hashtag1',
      header: 'Hashtag1',
    },
    {
      dataIndex: 'Hashtag2',
      header: 'Hashtag2',
    },
    {
      dataIndex: 'Hashtag3',
      header: 'Hashtag3',
    },
    {
      dataIndex: 'Hashtag4',
      header: 'Hashtag4',
    },
    {
      dataIndex: 'Hashtag5',
      header: 'Hashtag5',
    },
    {
      dataIndex: 'MigratedDate',
      header: 'MigratedDate',
      type: 'date',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
    {
      dataIndex: 'ShortCode',
      header: 'ShortCode',
    },
    {
      dataIndex: 'PlaceName',
      header: 'PlaceName',
    },
    {
      dataIndex: 'MigratedVideoURL',
      header: 'MigratedVideoURL',
    },
    {
      dataIndex: 'Date',
      header: 'Date',
      type: 'date',
    },
    {
      dataIndex: 'IsNeeded',
      header: 'IsNeeded',
      type: 'boolean',
      isRequired: true,
    },
    {
      dataIndex: 'IsAvailable',
      header: 'IsAvailable',
      type: 'boolean',
      isRequired: true,
    },
  ],
  comboTypes: [
    {
      type: 'Users',
      ValueField: 'Name',
      IDField: 'UserId',
    },
    {
      type: 'Place',
      ValueField: 'PlaceName',
    },
    {
      type: 'City',
      ValueField: 'CityName',
    },
  ],
  childs: () => [],
};
