import React, { PureComponent } from 'react';
import { UserRole } from '../entityconfig';
import BaseView from './BaseView';

class UserRoleView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(UserRole);
  }
}

export default UserRoleView;
