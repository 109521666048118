import { Actions } from '../core/redux-helper';

export const SecurityModule = {
  key: 'SecurityModule',
  title: 'SecurityModule',
  idColumn: 'SecurityModuleId',
  listAPI: Actions.listSecurityModule,
  getGridColumns: () => [
    {
      dataIndex: 'SecurityModuleId',
      header: 'SecurityModuleId',
    },
    {
      dataIndex: 'Name',
      header: 'Name',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'Name',
      header: 'Name',
      isRequired: true,
    },
  ],

  childs: () => [],
};
