import React, { Component } from 'react';
import { Actions } from 'core/redux-helper';
import { connect } from 'react-redux';
import { Pagination, Row, Col, Select, Modal, Checkbox, Button } from 'antd';
import CustomSelectLarge from 'components/CustomSelect/Select';
import { Comment } from '../../entityconfig';
import SimpleForm from '../simple-form';
import API from 'store/requests';

class CommentEditer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      count: 0,
      batchName: null,
      selectedRow: {},
      visible: false,
      activeRecordId: '',
      delcomment: {},
    };
    this.props.dispatch(
      Actions.requestForAPICommentBatch({
        action: 'RequestForAPI',
        identifier: 'CommentBatch',
        StoredProcedureName: 'LOAD_DISTINCT_COMMENT_BATCHES',
      }),
    );
    this.getComments(1);
  }

  deleteBatch = () => {
    this.sendBAtchReq('DeleteBatch', 'API_DELETE_COMMENTS_BATCH');
  };

  showBatch = () => {
    this.sendBAtchReq('ShowBatch', 'API_SHOW_COMMENTS_BATCH');
  };

  hideBatch = () => {
    this.sendBAtchReq('HideBatch', 'API_HIDE_COMMENTS_BATCH');
  };

  sendBAtchReq = (identifier, StoredProcedureName) => {
    if (this.state.batchName == null || this.state.batchName == '') {
      alert('select batch');
      return;
    }
    this.props.dispatch(
      Actions.operationRequestDeleteBatch({
        action: 'OperationRequest',
        identifier,
        StoredProcedureName,
        Params1: this.state.batchName,
      }),
    );
    this.getComments(this.state.pageNo, [
      { filterTerm: this.state.batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
    ]);
  };

  getComments = (pageNo, filter) => {
    this.props.dispatch(
      Actions.listComment({
        action: 'list',
        identifier: 'Comment',
        filterInfo: this.state.batchName ? filter : null,
        pageNo: pageNo - 1,
        pageSize: 20,
      }),
    );
  };

  onChange = page => {
    this.getComments(page, [
      { filterTerm: this.state.batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
    ]);
    this.setState({
      pageNo: page,
    });
  };

  componentWillReceiveProps({ count }) {
    if (count && this.props.count !== count) {
      this.setState({ count });
    }
  }

  handleChange = (batchName, opt) => {
    this.setState({ batchName }, () =>
      this.getComments(this.state.pageNo, [
        { filterTerm: batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
      ]),
    );
  };

  refresh = () => {
    this.getComments(this.state.pageNo, [
      { filterTerm: this.state.batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
    ]);

    this.props.dispatch(
      Actions.requestForAPICommentBatch({
        action: 'RequestForAPI',
        identifier: 'CommentBatch',
        StoredProcedureName: 'LOAD_DISTINCT_COMMENT_BATCHES',
      }),
    );
  };

  toggle = (tog, selectedRow) => {
    this.setState({
      visible: tog,
      selectedRow,
      activeRecordId: selectedRow ? selectedRow[Comment.idColumn] : '',
    });
  };

  deleteAll = () => {
    let { delcomment } = this.state;
    let vid = Object.values(delcomment).filter(item => item);
    let me = this;
    Modal.confirm({
      title: 'Are you sure u want to delete ' + vid.length + ' comments',
      content: (
        <Row gutter={16}>
          {vid &&
            vid.map((item, index) => (
              <Col className="gutter-row" span={6}>
                <img src={item.ImageUrl} style={{ height: '200px', width: 'calc(100%)' }} />
              </Col>
            ))}
        </Row>
      ),
      onOk: async () => {
        for (var item in vid) {
          me.confirmDelete(vid[item], vid.length - 1 == item);
        }
        this.setState({ delcomment: {} });
        //alert('competed');
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
      width: 1000,
    });
  };

  confirmDelete = (selectedRow, isRefresh) => {
    var idColumn = Comment.idColumn;
    var activeRecordId = selectedRow[idColumn];

    var payload = null;

    payload = {
      action: 'delete',
      CommentId: activeRecordId,
      identifier: 'Comment',
    };

    API.triggerPost('comment', payload)
      .then(response => {
        var data = response.data;
        if (data.success) {
          if (isRefresh) this.onChange(this.state.pageNo);
          //this.setState({ isRefresh: true }, () => (this.state.isRefresh = false));
        } else {
          alert('Failed');
        }
      })
      .catch(error => {
        alert(JSON.stringify(error));
      });
  };

  render() {
    const { data, Comment, dataS } = this.props;
    const { count, selectedRow, visible, activeRecordId, batchName, delcomment } = this.state;
    const { Option } = Select;

    const columns = [
      { title: 'BatchName', dataIndex: 'BatchName' },
      { title: 'UserId', type: 'combo', dataIndex: 'UserId', comboType: 'Users' },
      { title: 'CommentId', dataIndex: 'CommentId' },
      { title: 'PlaceId', type: 'combo', dataIndex: 'PlaceId', comboType: 'Place' },
      { title: 'InLocation', type: 'boolean', dataIndex: 'InLocation', isRequired: true },
      { title: 'CityId', type: 'combo', dataIndex: 'CityId', comboType: 'City' },
      { title: 'Hashtag', type: 'string', dataIndex: 'Hashtag' },
      { title: 'Hashtag1', type: 'string', dataIndex: 'Hashtag1' },
      { title: 'Hashtag2', type: 'string', dataIndex: 'Hashtag2' },
      { title: 'Hashtag3', type: 'string', dataIndex: 'Hashtag3' },
      { title: 'Hashtag4', type: 'string', dataIndex: 'Hashtag4' },
      { title: 'Hashtag5', type: 'string', dataIndex: 'Hashtag5' },
      { title: 'Emoji', type: 'string', dataIndex: 'Emoji' },
      { title: 'Latitude', type: 'float', dataIndex: 'Latitude' },
      { title: 'Longitude', type: 'float', dataIndex: 'Longitude' },
      { title: 'MigratedDate', type: 'datetime', dataIndex: 'MigratedDate' },
      { title: 'Date', type: 'datetime', dataIndex: 'Date' },
      {
        colSpan: 12,
        dataIndex: 'Text',
        isRequired: true,
        title: 'Text',
        type: 'textarea',
        rowSpan: 12,
      },
      {
        title: 'ImageUrl',
        type: 'imageUpload',
        dataIndex: 'ImageUrl',
        colSpan: 12,
        rowSpan: 13,
        height: 200,
        width: 200,
      },
    ];

    return (
      <div>
        <CustomSelectLarge
          onChange={this.handleChange}
          style={{ width: '20%', margin: '10px' }}
          value={batchName}
          className="edit-select"
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().startsWith(input.toLowerCase())
          }
          getPopupContainer={() =>
            document.getElementsByClassName('edit-select')[0].parentElement.parentElement
              .parentElement.parentElement
          }
          allowClear
          dropdownMatchSelectWidth={true}
          onInputKeyDown={event => {
            // TO DO : work on the keyDown chage value
            // .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active target this class to get value
          }}
        >
          {dataS.map(item => {
            return (
              <Option value={item} title={item}>
                {item}
              </Option>
            );
          })}
        </CustomSelectLarge>
        <Button onClick={this.refresh} style={{ margin: '10px' }}>
          Refresh
        </Button>
        <Button onClick={this.deleteAll} style={{ margin: '10px' }}>
          Delete Selected
        </Button>
        {batchName && batchName !== '' ? (
          <>
            <Button
              onClick={() =>
                this.toggle(true, { CommentId: 'NEW_RECORD', BatchName: this.state.batchName })
              }
            >
              Add a comment to this batch
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => this.toggle(true, { CommentId: 'NEW_RECORD' })}>
              Add a comment
            </Button>
          </>
        )}
        {batchName && batchName !== '' && Comment[0] && (
          <>
            <Button onClick={this.deleteBatch} style={{ margin: '10px' }}>
              Delete Batch
            </Button>
            {Comment[0].Hide ? (
              <Button onClick={this.showBatch} className="hidden-batch-buttons">
                BATCH hidden
              </Button>
            ) : (
              <Button onClick={this.hideBatch} className="live-batch-buttons">
                BATCH live
              </Button>
            )}
          </>
        )}
        <br />
        <Row gutter={16}>
          {Comment.map(item => (
            <Col className="gutter-row" span={6}>
              <Checkbox
                checked={delcomment[item.CommentId]}
                onChange={eve => {
                  this.setState({
                    delcomment: {
                      ...delcomment,
                      [item.CommentId]: eve.target.checked ? item : false,
                    },
                  });
                }}
              />
              <button onClick={() => this.toggle(true, item)}>edit</button>
              <button onClick={() => this.confirmDelete(item)}>Delete</button>
              <div>
                <img src={item.ImageUrl} style={{ height: '200px', width: 'calc(100%)' }} />
                <div style={{ height: 25 }}>
                  {item.Latitude},{item.Longitude}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <br />
        {count !== 0 ? (
          <Pagination
            defaultPageSize={20}
            current={this.state.pageNo}
            onChange={this.onChange}
            total={count}
          />
        ) : (
          ''
        )}
        <Modal
          visible={visible}
          footer={null}
          onCancel={() => {
            return this.toggle(false);
          }}
          width={1024}
          destroyOnClose
        >
          <SimpleForm
            columns={columns}
            selectedRow={selectedRow}
            identifier={'Comment'}
            apiIdentifier={'Comment'}
            toggle={this.toggle}
            visible={visible}
            config={Comment}
            activeRecordId={activeRecordId}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({
  requestforapi_commentbatch,
  list_10052  ,
  operationrequest_showbatch,
  operationrequest_hidebatch,
  operationrequest_deletebatch,
}) => {
  let data = [],
    dataS = [],
    Comment = [],
    count = null;
    debugger
  if (requestforapi_commentbatch.data && requestforapi_commentbatch.data.data) {
    data = requestforapi_commentbatch.data.data;
    data.forEach(item => dataS.push(item.BatchName));
    dataS.sort();
  }
  if (list_10052.data && list_10052.data.data) {
    Comment = list_10052.data.data;
    count = list_10052.data.total;
  }
  return { data, Comment, count, dataS };
};

export default connect(mapStateToProps)(CommentEditer);
