import { Actions } from '../core/redux-helper';

export const Product = {
  key: 'Product',
  title: 'Product',
  idColumn: 'ProductId',
  listAPI: Actions.listProduct,
  getGridColumns: () => [
    {
      dataIndex: 'ProductId',
      header: 'ProductId',
    },
    {
      dataIndex: 'Title',
      header: 'Title',
    },
    {
      dataIndex: 'Image',
      header: 'Image',
    },
    {
      dataIndex: 'Rate',
      header: 'Rate',
      type: 'number',
    },
    {
      dataIndex: 'RegularPrice',
      header: 'RegularPrice',
      type: 'number',
    },
    {
      dataIndex: 'SalePrice',
      header: 'SalePrice',
      type: 'number',
    },
    {
      dataIndex: 'Checked',
      header: 'Checked',
      type: 'boolean',
    },
    {
      dataIndex: 'ProductType',
      header: 'ProductType',
    },
    {
      dataIndex: 'SubTitle',
      header: 'SubTitle',
    },
    {
      dataIndex: 'UserId',
      header: 'UserId',
      type: 'combo',
      comboType: 'User',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'Title',
      header: 'Title',
      isRequired: true,
    },
    {
      dataIndex: 'Image',
      header: 'Image',
      isRequired: true,
    },
    {
      dataIndex: 'Rate',
      header: 'Rate',
      type: 'float',
    },
    {
      dataIndex: 'RegularPrice',
      header: 'RegularPrice',
      type: 'float',
    },
    {
      dataIndex: 'SalePrice',
      header: 'SalePrice',
      type: 'float',
    },
    {
      dataIndex: 'Checked',
      header: 'Checked',
      type: 'boolean',
    },
    {
      dataIndex: 'ProductType',
      header: 'ProductType',
    },
    {
      dataIndex: 'SubTitle',
      header: 'SubTitle',
    },
  ],
  comboTypes: [],
  childs: () => [],
};
