import React, { PureComponent } from 'react';
import { SurveyQuestion } from '../entityconfig';
import BaseView from './BaseView';

class SurveyQuestionView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(SurveyQuestion);
  }
}

export default SurveyQuestionView;
