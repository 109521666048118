import React, { PureComponent } from 'react';
import { Survey } from '../entityconfig/Survey';
import BaseView from './BaseView';

class SurveyView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(Survey);
  }
}

export default SurveyView;
