import React, { PureComponent } from 'react';
import { City } from '../entityconfig';
import BaseView from './BaseView';

class CityView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(City);
  }
}

export default CityView;
