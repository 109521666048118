import React, { Component } from 'react';
import { List, Card, Select, Col, Row, Input, Form, Button } from 'antd';
import { Actions } from 'core/redux-helper';
import { connect } from 'react-redux';
import CustomSelect from 'components/CustomSelect/Select';
import { CSVLink } from 'react-csv';

const { Option } = Select;

class VideoLinkUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      inputData: {
        cityId: '',
        placeId: '',
        videoUrl: '',
      },
    };
    let combos = [
      {
        type: 'City',
        ValueField: 'CityName',
      },
      {
        type: 'Place',
        ValueField: 'PlaceName',
      },
    ];
    props.dispatch(
      Actions.listCity({
        action: 'list',
        identifier: 'city',
        pageSize: 1,
        comboTypes: [
          { type: 'City', ValueField: 'CityName' },
          { type: 'Place', ValueField: 'PlaceName', FilterBy: 'City' },
        ],
      }),
    );
  }

  onAdd = () => {
    const { inputData } = this.state;
    if (inputData.cityId != '' && inputData.videoUrl != '') {
      this.setState({
        data: [...this.state.data, this.state.inputData],
        inputData: {
          cityId: '',
          placeId: '',
          videoUrl: '',
        },
      });
    } else {
      alert('please fill the cityId & videoUrl');
    }
  };

  render() {
    const { data, inputData } = this.state;
    const { places, cities } = this.props;

    return (
      <div>
        <Form>
          <Row gutter={12}>
            <Col xs={24} md={6} lg={6}>
              <Form.Item label="CityId" required>
                <CustomSelect
                  showSearch
                  value={inputData.cityId}
                  onChange={value => this.setState({ inputData: { ...inputData, cityId: value } })}
                  filterOption={(input, option) => {
                    if (!option.props.children) {
                      return false;
                    }
                    return input
                      ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : true;
                  }}
                >
                  {cities.map(item => {
                    return <Option value={item.LookupId}>{item.DisplayValue}</Option>;
                  })}
                </CustomSelect>
              </Form.Item>
            </Col>
            <Col xs={24} md={6} lg={6}>
              <Form.Item label="PlaceId">
                <CustomSelect
                  showSearch
                  value={inputData.placeId}
                  onChange={value => this.setState({ inputData: { ...inputData, placeId: value } })}
                  filterOption={(input, option) => {
                    if (!option.props.children) {
                      return false;
                    }
                    return input
                      ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : true;
                  }}
                >
                  {places
                    .filter(item =>
                      inputData.cityId !== '' ? item.FilterBy === inputData.cityId : true,
                    )
                    .map(item => {
                      return <Option value={item.LookupId}>{item.DisplayValue}</Option>;
                    })}
                </CustomSelect>
              </Form.Item>
            </Col>
            <Col xs={24} md={10} lg={10}>
              <Form.Item label="VideoUrl" required>
                <Input
                  value={inputData.videoUrl}
                  onChange={e =>
                    this.setState({
                      inputData: { ...inputData, videoUrl: e.target.value },
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={2} lg={2}>
              <Button type="primary" style={{ marginTop: '41px' }} onClick={this.onAdd.bind(this)}>
                Add
              </Button>
            </Col>
          </Row>
        </Form>

        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <Card style={{ width: 250 }}>
                CityId: {item.cityId}
                <br />
                PlaceId: {item.placeId}
                <br />
                Video URL: {item.videoUrl}
                <br />
                {/* {loadingIndex == index && 'loading....'}
                <br />
                Message: {message[index]} */}
              </Card>
            </List.Item>
          )}
        />
        <CSVLink data={data} separator={','}>
          Download Csv after adding data
        </CSVLink>
      </div>
    );
  }
}

const mapStateToProps = ({ combos }) => {
  let places = [],
    cities = [];
  if (combos) {
    if (combos.City) {
      cities = combos.City;
    }
    if (combos.Place) {
      places = combos.Place;
    }
  }
  return { places, cities };
};

export default connect(mapStateToProps)(VideoLinkUploader);
