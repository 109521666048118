import { Actions } from '../core/redux-helper';

export const ControlRoomRequestReply = {
  key: 'ControlRoomRequestReply',
  title: 'ControlRoomRequestReply',
  idColumn: 'ControlRoomRequestReplyId',
  listAPI: Actions.listControlRoomRequestReply,
  getGridColumns: () => [
    {
      dataIndex: 'ControlRoomRequestReplyId',
      header: 'ControlRoomRequestReplyId',
    },
    {
      dataIndex: 'ControlRoomRequestId',
      header: 'ControlRoomRequestId',
      type: 'combo',
      comboType: 'ControlRoomRequest',
    },
    {
      dataIndex: 'AttachmentURL',
      header: 'AttachmentURL',
    },
    {
      dataIndex: 'AttachmentTypeId',
      header: 'AttachmentTypeId',
      type: 'combo',
      comboType: 'AttachmentType',
    },
    {
      dataIndex: 'Description',
      header: 'Description',
    },
    {
      dataIndex: 'UserId',
      header: 'UserId',
      type: 'combo',
      comboType: 'User',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'ControlRoomRequestId',
      header: 'ControlRoomRequestId',
      type: 'combo',
      comboType: 'ControlRoomRequest',
      isRequired: true,
    },
    {
      dataIndex: 'AttachmentURL',
      header: 'AttachmentURL',
    },
    {
      dataIndex: 'AttachmentTypeId',
      header: 'AttachmentTypeId',
      type: 'combo',
      comboType: 'AttachmentType',
      isRequired: true,
    },
    {
      dataIndex: 'Description',
      header: 'Description',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
  ],
  comboTypes: [
    {
      type: 'ControlRoomRequest',
      ValueField: 'Title',
      IDField: 'ControlRoomRequestId',
    },
    {
      type: 'AttachmentType',
      ValueField: 'AttachmentTypeName',
      IDField: 'AttachmentTypeId',
    },
  ],
  childs: () => [],
};
