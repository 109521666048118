import { Actions } from '../core/redux-helper';

export const Logs = {
  key: 'Logs',
  title: 'Logs',
  idColumn: 'Id',
  listAPI: Actions.listLogs,
  getGridColumns: () => [
    {
      dataIndex: 'Id',
      header: 'Id',
      type: 'number',
    },
    {
      dataIndex: 'Message',
      header: 'Message',
    },
    {
      dataIndex: 'MessageTemplate',
      header: 'MessageTemplate',
    },
    {
      dataIndex: 'Level',
      header: 'Level',
    },
    {
      dataIndex: 'TimeStamp',
      header: 'TimeStamp',
      type: 'date',
    },
    {
      dataIndex: 'Exception',
      header: 'Exception',
    },
    {
      dataIndex: 'Properties',
      header: 'Properties',
    },
    {
      dataIndex: 'IsSkip',
      header: 'IsSkip',
      type: 'boolean',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'Id',
      header: 'Id',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'Message',
      header: 'Message',
    },
    {
      dataIndex: 'MessageTemplate',
      header: 'MessageTemplate',
    },
    {
      dataIndex: 'Level',
      header: 'Level',
    },
    {
      dataIndex: 'Exception',
      header: 'Exception',
    },
    {
      dataIndex: 'Properties',
      header: 'Properties',
    },
    {
      dataIndex: 'IsSkip',
      header: 'IsSkip',
      type: 'boolean',
    },
  ],
  comboTypes: [],
  childs: () => [],
};
