import React, { useState } from 'react';
import axios from 'axios';
import { URL } from '../app-config/index';
import { PulseLoader } from 'react-spinners';

function Application() {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15vh',
  };

  const inputStyle = {
    marginBottom: '5px',
    marginRight: '-200px',
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!selectedFile) {
      alert('Please select a file before submitting.');
      return;
    }

    setTimeout(async () => {
      try {
        const formData = new FormData();

        formData.append('file', selectedFile);
        const response = await axios.post(
          URL + '/api/v1/ProcessEngine/GenerateApplication',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        setLoading(false);

        if (response.status === 200) {
          alert('API Response: ' + JSON.stringify(response.data));
        } else {
          alert('API request failed with status: ' + response.status);
        }
      } catch (error) {
        alert('An error occurred while making the API request: ' + error.message);
      } finally {
      }
    }, 1000);
  };

  return (
    <div style={containerStyle}>
      <input type="file" style={inputStyle} onChange={handleFileChange} />
      <button onClick={handleSubmit}>Submit</button>
      {loading && <PulseLoader size={10} color={'#123abc'} loading={loading} />}{' '}
      {/* Display the round spinner */}
    </div>
  );
}

export default Application;
