import { Actions } from '../core/redux-helper';

export const City = {
  key: 'City',
  title: 'City',
  idColumn: 'CityId',
  listAPI: Actions.listCity,
  getGridColumns: () => [
    {
      dataIndex: 'CityId',
      header: 'CityId',
    },
    {
      dataIndex: 'CityName',
      header: 'CityName',
    },
    {
      dataIndex: 'Country',
      header: 'Country',
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'CityName',
      header: 'CityName',
      isRequired: true,
    },
    {
      dataIndex: 'Country',
      header: 'Country',
      isRequired: true,
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'float',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'float',
    },
  ],
  comboTypes: [],
  childs: () => [],
};
