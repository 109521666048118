import { APIVersion, URL } from 'app-config';
import Axios from 'axios';
import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import API from 'store/requests';

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
};

class EventsUploader extends Component {
  state = { eventsData: [], cities: [], places: [] };

  getEventsData = eventsData => {
    let cities = [],
      places = [];
    eventsData.map(item => {
      if (cities.indexOf(item.cityname) < 0) {
        cities.push(item.cityname);
      }
      if (places.indexOf(item.placename) < 0) {
        places.push(item.placename);
      }
    });
    cities = cities.map(item => ({ name: item, value: '' }));
    places = places.map(item => ({ name: item, value: '' }));
    this.setState({ eventsData, places, cities });
  };

  onSubmit = () => {
    const { eventsData, places, cities } = this.state;
    for (let eventData of eventsData) {
      const { eventdescripton, eventimage, migratedImage, eventstartdate, eventtitle } = eventData;
      let request = {
        CityId: '',
        PlaceId: '',
        EventTitle: eventtitle,
        EventDate: new Date(eventstartdate).toLocaleDateString(),
        EventDescription: eventdescripton,
        EventImage: eventimage.split('?')[0],
        MigratedImage: migratedImage,
        EventStartTime: new Date(eventstartdate).toLocaleTimeString(),
        action: 'insert',
        apiIdentifier: 'Event',
        id: 0,
        BathName: 'testing',
      };
      let place = places.filter(item => item.name === eventData.placename),
        citie = cities.filter(item => item.name === eventData.cityname);
      if (place[0].value !== '' && citie[0].value !== '' && place[0].value && citie[0].value) {
        request.PlaceId = place[0].value;
        request.CityId = citie[0].value;
        Axios.post(URL + APIVersion + 'Event/Insert', request)
          .then(response => {
            var data = response.data;
            if (data.success) {
              console.log('done');
              this.setState({ isRefresh: true }, () => (this.state.isRefresh = false));
            } else {
              console.log('Failed');
            }
          })
          .catch(error => {
            console.log(JSON.stringify(error));
          });
      }
    }
  };

  getPlaces = placeData => {
    const places = [...this.state.places];
    placeData.map(item => {
      let index = places.findIndex(
        place => place.name.toLowerCase() === item.placename.replace('\n', '').toLowerCase(),
      );
      if (index >= 0) places[index].value = item.placeid;
    });
    this.setState({ places });
  };

  render() {
    const { cities, places } = this.state;
    return (
      <div>
        <CSVReader
          cssClass="react-csv-input"
          label="Choose a file of Events"
          onFileLoaded={this.getEventsData}
          parserOptions={papaparseOptions}
        />
        <CSVReader
          cssClass="react-csv-input"
          label="Choose a file of Places With Place ID's"
          onFileLoaded={this.getPlaces}
          parserOptions={papaparseOptions}
        />
        <h3>CITIES</h3>
        {cities.map((item, index) => {
          return (
            <>
              {item.name}:{' '}
              <input
                value={item.value}
                onChange={e => {
                  let cities = [...this.state.cities];
                  cities[index].value = e.target.value;
                  this.setState({ cities });
                }}
              />
              <br />
            </>
          );
        })}
        <h3>PLACES</h3>
        {places.map((item, index) => {
          return (
            <>
              {item.name}:{' '}
              <input
                value={item.value}
                onChange={e => {
                  let places = [...this.state.places];
                  places[index].value = e.target.value;
                  this.setState({ cities });
                }}
              />
              <br />
            </>
          );
        })}
        <button onClick={this.onSubmit}>Submit with avialable keys</button>
      </div>
    );
  }
}

export default EventsUploader;
