import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { FormControl } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
//import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreVertIcon from '@material-ui/icons/MoreVert';
//import CardHeader from '@mui/material/CardHeader';
import { CardHeader } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    minWidth: 275,
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    fontFamily: 'Arial, sans-serif',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  pos: {
    marginBottom: 12,
  },
}));

const clientData = [
  {
    Name: 'Insurance Product',
    Count: 100,
    ActiveCount: 80,
    InActiveCount: 20,
  },
  {
    Name: 'EV Bikes',
    Count: 200,
    ActiveCount: 197,
    InActiveCount: 3,
  },
  {
    Name: 'ShellPolicy LLC',
    Count: 120,
    ActiveCount: 97,
    InActiveCount: 23,
  },
  {
    Name: 'Life Policies',
    Count: 100,
    ActiveCount: 40,
    InActiveCount: 60,
  },
  {
    Name: 'Motars',
    Count: 100,
    ActiveCount: 80,
    InActiveCount: 20,
  },
];

const CardItem = ({Name, Count, ActiveCount, InActiveCount}) => (
  <Grid item xs={4}>
    <Card
      style={{
        maxWidth: 345,
        borderRadius: 10,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
        border: '1px solid #e0e0e0',
      }}
    >
      <CardHeader
        action={
          <IconButton aria-label="settings" style={{ marginTop: '-10px', color: 'white' }}>
            <MoreVertIcon />
          </IconButton>
        }
        titleTypographyProps={{
          variant: 'h6',
          fontSize: '15px',
          fontWeight: 'bold',
          color: 'white',
          fontFamily: 'Arial, sans-serif',
        }}
        title={Name}
        style={{ backgroundColor: '#3f51b5', height: '50px' }}
      />
      {/* <hr style={{ borderTop: '1px solid #e0e0e0', marginTop: '-10px' }} /> */}
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '30px',
            textAlign: 'center',
            marginTop: '-30px',
            marginBottom: '-30px',
          }}
        >
          <div>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#3E3F4A',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {Count}
            </span>
            <br />
            <span
              style={{
                fontSize: '15px',
                color: '#757575',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {Name}
            </span>
          </div>
          <div>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#3E3F4A',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {ActiveCount}
            </span>
            <br />
            <span
              style={{
                fontSize: '15px',
                color: '#757575',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              Active
            </span>
          </div>
          <div>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#3E3F4A',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {InActiveCount}
            </span>
            <br />
            <span
              style={{
                fontSize: '15px',
                color: '#757575',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              Inactive
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  </Grid>
);

export default function Test1() {
  const [existClient, setExistClient] = React.useState('');
  const handleChange = event => {
    setExistClient(event.target.value);
  };
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <CardContent>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {/* Search Bar */}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Client"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <Button color="inherit">Search</Button>
        </Toolbar>
      </AppBar>

      <br />

      <Card sx={{}}>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                marginRight: '10px',
                color: '#333547',
                fontWeight: 'bold',
                fontSize: '18px',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              List of clients
            </p>
            <div>
              <FormControl sx={{ m: 0, minWidth: 200, p: 0 }}>
                <TextField
                  id="demo-simple-select-helper"
                  select
                  value={existClient}
                  onChange={handleChange}
                  label="Existing Client"
                  variant="outlined"
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    },
                  }}
                  style={{ fontFamily: 'Arial, sans-serif' }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </TextField>
              </FormControl>
            </div>
          </div>
          <br />
          <br />

          <Grid container spacing={3}>
            {clientData.map(item => <CardItem {...item} />)}
          </Grid>
        </CardContent>
      </Card>
    </CardContent>
  );
}
