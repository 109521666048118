import { Actions } from '../core/redux-helper';

export const Comment = {
  key: 'Comment',
  title: 'Comment',
  idColumn: 'CommentId',
  listAPI: Actions.listComment,
  getGridColumns: () => [
    {
      dataIndex: 'CommentId',
      header: 'CommentId',
    },
    {
      dataIndex: 'ActivityId',
      header: 'ActivityId',
      type: 'combo',
      comboType: 'Activity',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'UserId',
      header: 'UserId',
      type: 'combo',
      comboType: 'User',
    },
    {
      dataIndex: 'Text',
      header: 'Text',
    },
    {
      dataIndex: 'LikeCount',
      header: 'LikeCount',
      type: 'number',
    },
    {
      dataIndex: 'DislikeCount',
      header: 'DislikeCount',
      type: 'number',
    },
    {
      dataIndex: 'InLocation',
      header: 'InLocation',
      type: 'boolean',
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'ActivityId',
      header: 'ActivityId',
      type: 'combo',
      comboType: 'Activity',
      isRequired: true,
    },
    {
      dataIndex: 'Text',
      header: 'Text',
    },
    {
      dataIndex: 'LikeCount',
      header: 'LikeCount',
      type: 'int',
    },
    {
      dataIndex: 'DislikeCount',
      header: 'DislikeCount',
      type: 'int',
    },
    {
      dataIndex: 'InLocation',
      header: 'InLocation',
      type: 'boolean',
      isRequired: true,
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'float',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'float',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
  ],
  comboTypes: [
    {
      type: 'Activity',
      ValueField: 'Text',
      IDField: 'ActivityId',
    },
  ],
  childs: () => [],
};
