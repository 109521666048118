import { Actions } from '../core/redux-helper';

export const ActivityType = {
  key: 'ActivityType',
  title: 'ActivityType',
  idColumn: 'ActivityTypeId',
  listAPI: Actions.listActivityType,
  getGridColumns: () => [
    {
      dataIndex: 'ActivityTypeId',
      header: 'ActivityTypeId',
    },
    {
      dataIndex: 'ActivityTypeName',
      header: 'ActivityTypeName',
    },
    {
      dataIndex: 'Type',
      header: 'Type',
    },
    {
      dataIndex: 'Image',
      header: 'Image',
    },
    {
      dataIndex: 'IsControl',
      header: 'IsControl',
      type: 'boolean',
    },
    {
      dataIndex: 'Marker',
      header: 'Marker',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'ActivityTypeName',
      header: 'ActivityTypeName',
      isRequired: true,
    },
  ],
  comboTypes: [],
  childs: () => [],
};
