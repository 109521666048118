import React, { PureComponent } from 'react';
import { ControlRoomRequest } from '../entityconfig';
import BaseView from './BaseView';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ListIcon from '@material-ui/icons/List';
import Fab from '@material-ui/core/Fab';
import AppsIcon from '@material-ui/icons/Apps';
import Grid from '@material-ui/core/Grid';

class ControlRoomRequestView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(ControlRoomRequest, true);
    this.state = {
      cardView: true,
    };
  }

  changeViewMode = viewMode => {
    this.setState({ cardView: viewMode });
    this.config = this.constructConfig(ControlRoomRequest, viewMode);
  };

  gridHeader = ({ gridPanel }) => {
    this.gridPanel = gridPanel;
    const activityFilter = type => {
      var activityTypeCombos = gridPanel.props.combos['ControlRoomRequest'];
      var filterList = [];
      activityTypeCombos &&
        activityTypeCombos
          .filter(item => item.Type == type)
          .forEach(item => {
            filterList.push(item.LookupId);
          });
      var model = {
        ActivityTypeId: {
          type: 'listCustom',
          value: filterList.join(','),
        },
      };
      gridPanel.gridApi.setFilterModel(model);
    };
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: 10 }}
      >
        <Grid item xs>
          {this.gridPanel.buildButtons(gridPanel.props.isFetching)}
        </Grid>
        <Grid container xs justifyContent="flex-end">
          <Fab
            size="small"
            onClick={() => {
              this.setState({ cardView: true });
              this.changeViewMode(true);
            }}
            style={{ marginRight: 5 }}
            className={this.state.cardView === true ? 'activeView' : 'inactiveView'}
          >
            <AppsIcon
              className={this.state.cardView === true ? 'activeViewIcon' : 'inactiveViewIcon'}
            />
          </Fab>
          <Fab
            size="small"
            onClick={() => {
              this.setState({ cardView: false });
              this.changeViewMode(false);
            }}
            className={this.state.cardView === false ? 'activeView' : 'inactiveView'}
          >
            <ListIcon
              className={this.state.cardView === false ? 'activeViewIcon' : 'inactiveViewIcon'}
            />
          </Fab>
        </Grid>
      </Grid>
    );
  };
}

export default ControlRoomRequestView;
