import React, { PureComponent } from 'react';
import { SecurityModule } from 'entityconfig/SecurityModule';
import BaseView from './BaseView';

class SecurityModuleView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(SecurityModule);
  }
}

export default SecurityModuleView;
