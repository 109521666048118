import { Actions } from '../core/redux-helper';

export const WellnessCenterType = {
  key: 'WellnessCenterType',
  title: 'WellnessCenterType',
  idColumn: 'WellnessCenterTypeId',
  listAPI: Actions.listWellnessCenterType,
  getGridColumns: () => [
    {
      dataIndex: 'WellnessCenterTypeId',
      header: 'WellnessCenterTypeId',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
    {
      dataIndex: 'WellnessCenterTypeName',
      header: 'WellnessCenterTypeName',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'WellnessCenterTypeName',
      header: 'WellnessCenterTypeName',
    },
  ],
  comboTypes: [],
  childs: () => [],
};
