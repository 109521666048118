import { Actions } from '../core/redux-helper';

export const User = {
  key: 'User',
  title: 'User',
  idColumn: 'UserId',
  listAPI: Actions.listUser,
  getGridColumns: () => [
    {
      dataIndex: 'UserId',
      header: 'UserId',
    },
    {
      dataIndex: 'UserRoleId',
      header: 'UserRoleId',
      type: 'combo',
      comboType: 'UserRole',
    },
    {
      dataIndex: 'Name',
      header: 'Name',
    },
    {
      dataIndex: 'ProfileImage',
      header: 'ProfileImage',
    },
    {
      dataIndex: 'Age',
      header: 'Age',
      type: 'number',
    },
    {
      dataIndex: 'Sex',
      header: 'Sex',
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Address',
      header: 'Address',
    },
    {
      dataIndex: 'Designation',
      header: 'Designation',
    },
    {
      dataIndex: 'EducationalInstitute',
      header: 'Educational Institute',
    },
    {
      dataIndex: 'GuardianName',
      header: 'GuardianName',
    },
    {
      dataIndex: 'GuardianPhone',
      header: 'GuardianPhone',
      type: 'number',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ReferalCode',
      header: 'ReferalCode',
    },
    {
      dataIndex: 'UserDescription',
      header: 'UserDescription',
    },
    {
      dataIndex: 'Phone',
      header: 'Phone',
      type: 'number',
    },
    {
      dataIndex: 'Email',
      header: 'Email',
    },
    {
      dataIndex: 'DateofBirth',
      header: 'DateofBirth',
      type: 'date',
    },
    {
      dataIndex: 'Blocked',
      header: 'Blocked',
      type: 'boolean',
    },
    {
      dataIndex: 'IsGHMCRegistered',
      header: 'IsGHMCRegistered',
      type: 'boolean',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'UserRoleId',
      header: 'UserRoleId',
      type: 'combo',
      comboType: 'UserRole',
      isRequired: true,
    },
    {
      dataIndex: 'Name',
      header: 'Name',
      isRequired: true,
    },
    {
      dataIndex: 'ProfileImage',
      header: 'ProfileImage',
    },
    {
      dataIndex: 'Age',
      header: 'Age',
      type: 'int',
    },
    {
      dataIndex: 'Sex',
      header: 'Sex',
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'float',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'float',
    },
    {
      dataIndex: 'Address',
      header: 'Address',
    },
    {
      dataIndex: 'Designation',
      header: 'Designation',
    },
    {
      dataIndex: 'EducationalInstitute',
      header: 'Educational Institute',
    },
    {
      dataIndex: 'GuardianName',
      header: 'GuardianName',
    },
    {
      dataIndex: 'GuardianPhone',
      header: 'GuardianPhone',
      type: 'int',
    },
    {
      dataIndex: 'ReferalCode',
      header: 'ReferalCode',
    },
    {
      dataIndex: 'UserDescription',
      header: 'UserDescription',
    },
    {
      dataIndex: 'Phone',
      header: 'Phone',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'Email',
      header: 'Email',
      isRequired: true,
    },
    {
      dataIndex: 'DateofBirth',
      header: 'DateofBirth',
      type: 'date',
    },
    {
      dataIndex: 'Blocked',
      header: 'Blocked',
      type: 'boolean',
    },
    {
      dataIndex: 'IsGHMCRegistered',
      header: 'IsGHMCRegistered',
      type: 'boolean',
    },
  ],
  comboTypes: [
    {
      type: 'UserRole',
      ValueField: 'UserRoleName',
      IDField: 'UserRoleId',
    },
  ],
  childs: () => [],
};
