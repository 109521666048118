const paginationColor = ['#24b5ee'];
const paginationDropDownColor = ['#24b5ee'];
const navBarBackGroundColor = ['#24b5ee'];
const LogoBackGroundColor = ['#00308F'];
// const LogoBackGroundColor = ['#24b5ee'];
const adminContentBackGroundColor = ['#F8F8F8'];
const sideBarBackGroundColor = ['#00308F'];
// const sideBarBackGroundColor = ['#EBEBEB'];
const sideBarActiveItemColor = ['#C9C9C9'];

export {
  paginationColor,
  paginationDropDownColor,
  navBarBackGroundColor,
  adminContentBackGroundColor,
  sideBarBackGroundColor,
  sideBarActiveItemColor,
  LogoBackGroundColor
};
