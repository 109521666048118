import React, { PureComponent } from 'react';
import { News } from '../entityconfig';
import BaseView from './BaseView';

class NewsView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(News);
  }
}

export default NewsView;
