import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';

// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';

// core components
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import { disableRippleEffect } from '../../app-config/index';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';

const modal = Modal;

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.state = {
      open: false,
      username: localStorage.getItem('username'),
      //isSSOUser: localStorage.getItem('isSSOUser'),
      //themeColor: localStorage.getItem('themeColor'),
      firstName: '',
      lastName: '',
      //profileName: '',
    };
  }
  onLanguageChange(e) {
    if (e) {
      let selectedLang = e.target.value;
      this.props.i18n.changeLanguage(selectedLang);
      localStorage.selectedLanguage = selectedLang;
      moment.locale(selectedLang);
      window.location.reload();
    }
  }
  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };
  clearuser = () => {
    localStorage.clear();
    this.props.history.push('/');
    this.handleToggle();
  };
  logOut = isSSOUser => {
    const { t } = this.props;
    var selectedLang = localStorage.selectedLanguage || '';

    modal.confirm({
      title: t('Leave site?'),
      onOk: () => {
        localStorage.selectedLanguage = selectedLang;
        localStorage.clear();
        this.props.history.push('/');
        this.handleToggle();
      },
      // onCancel:{},
      content: t('Changes you made may not be saved'),
      okText: t('Leave'),
      cancelText: t('Cancel'),
      okButtonProps: { style: { backgroundColor: '#24b5ee', border: '1px #24b5ee solid' } },
      cancelButtonProps: { style: { border: '1px #24b5ee solid', color: '#24b5ee' } },
    });
  };
  ProfileName = (firstName, lastName) => {
    var primaryEmail = localStorage.getItem('primaryEmail');
    var res = '';
    if (firstName) {
      res = firstName.split('')[0];
    }
    if (lastName) {
      res += lastName.split('')[0];
    }
    if (res === '') {
      res = (primaryEmail || '').split('')[0];
    }
    return res;
  };
  componentDidMount() {
    var firstName = localStorage.getItem('firstname');
    var lastName = localStorage.getItem('lastname');
    this.setState({ profileName: this.ProfileName(firstName, lastName), firstName, lastName });
  }
  render() {
    const { classes, rtlActive, t, textColor } = this.props;
    const { open, username, isSSOUser, firstName, lastName, profileName } = this.state;
    const searchButton =
      classes.top +
      ' ' +
      classes.searchButton +
      ' ' +
      classNames({
        [classes.searchRTL]: rtlActive,
      });
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
      [classes.dropdownItemRTL]: rtlActive,
    });
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });

    return (
      <div className={wrapper} style={{ display: 'flex' }}>
        <div className="icon-box">
          <Badge color="secondary" overlap="circle" badgeContent="4">
            <span className="nav-notifications">
              <NotificationsActiveRoundedIcon />
            </span>
          </Badge>
        </div>
        <div className="icon-box">
          <Badge color="secondary" overlap="circle" badgeContent="7">
            <span className="nav-notifications">
              <NotificationsActiveRoundedIcon />
            </span>
          </Badge>
        </div>
        <div className="user-title">
          <span>{t('Control Room')}</span> <br />
          <span>{t('Super admin')}</span>
        </div>
        {/* <Button class="self-button-color" style={{ border: 'unset'}} onClick={this.handleToggle}>
         {username}
        </Button> */}
        <Popper
          open={open}
          role={undefined}
          transition
          // disablePortal
          style={{
            position: 'absolute',
            left: 'unset !important',
            top: 'unset !important',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                position: 'absolute',
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                left: 'unset !important',
                top: 'unset !important',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleToggle}>
                  <div
                    autoFocusItem={open}
                    style={{
                      position: 'fixed',
                      backgroundColor: '#eeeeee',
                      borderRadius: '3px',
                      right: '25px',
                      top: '50px',
                      width: '250px',
                      padding: '10px',
                      boxShadow: 'rgba(51, 51, 51, 0.45) 1px 1px 3px 1px',
                      zIndex: '9',
                      padding: '15px',
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: 'bold', margin: '2px 0px' }}>
                        {firstName} {lastName}
                      </p>
                      {/* <p style={{ fontWeight: 'bold', margin: '2px' }}>lastName</p> */}
                      <p style={{ fontSize: 'smaller', fontWeight: '500px' }}>{username}</p>
                      <div
                        id="google_translate_element"
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            color: textColor ? textColor : 'black',
                            marginRight: '1em',
                          }}
                        >
                          {t('Language')}
                        </div>
                        <select
                          defaultValue={
                            localStorage.selectedLanguage ? localStorage.selectedLanguage : '1'
                          }
                          style={{ width: 130 }}
                          onChange={this.onLanguageChange}
                        >
                          <option value="1" hidden="hidden">
                            English
                          </option>
                          <option value="en">English</option>
                          <option value="es">{t('Spanish')}</option>
                          <option value="pt">{t('Portuguese')}</option>
                          <option value="ar">{t('Arabic')}</option>
                          <option value="cn">{t('Chinese')}</option>
                        </select>
                      </div>
                      <br></br>
                    </div>
                    <div style={{ marginTop: '25px' }}>
                      <Button onClick={() => this.logOut(isSSOUser)} className="main-button-color">
                        {t('Log out')}
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        {/* <Dropdown overlay={menu} style={{ top: '45px' }}>
          <span className="ant-dropdown-link">
            <Text onClick>{this.state.username}</Text>
          </span>
        </Dropdown> */}
        <Button
          style={{
            backgroundColor: '#FFF',
            borderRadius: '50px',
          }}
          aria-label="Person"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
          onClick={this.handleToggle}
        >
          <span style={{ color: 'black', fontWeight: 'bold' }}>{t('NF')}</span>
          {/* <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }

          /> */}
          {/* <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden> */}
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};
const enhance = compose(
  connect(),
  withTranslation(),
  withRouter,
  withStyles(adminNavbarLinksStyle),
);

export default enhance(HeaderLinks);
