import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import Axios from 'axios';

const URL = 'http://4.236.155.119:5000';

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
};

export default class AutoText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadUrls: [],
    };
  }
  onSubmit = () => {
    console.log(JSON.stringify(this.state.leadUrls));

    /*const params = {
      action: 'text',
      textToSend: 'Are you still in Market ?',
      all_urls: this.state.leadUrls,
      userId: '1234',
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    };

    fetch(URL, requestOptions)
      .then(response => {
        debugger;
        return response.json();
      })
      .then(result => {
        debugger;
      })
      .catch(error => {
        debugger;
      });

      Axios.post(URL, params)
      .then(response => {
        debugger;
        return response.json();
      })
      .then(result => {
        debugger;
      })
      .catch(error => {
        debugger;
      });
    debugger;*/
  };
  readCSV = data => {
    var leadUrls = [];
    for (var item of data) {
      leadUrls.push(item.lead_url);
    }
    this.setState({
      leadUrls,
    });
  };
  render() {
    return (
      <>
        <br />
        <br />
        <CSVReader
          cssClass="react-csv-input"
          label="Select CSV File"
          onFileLoaded={this.readCSV}
          parserOptions={papaparseOptions}
        />
        <button onClick={this.onSubmit}>Submit</button>
      </>
    );
  }
}
