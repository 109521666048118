import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import { List, Card } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';
import { URL, APIVersion } from 'app-config';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
};

class CsvUserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadingIndex: null,
      message: {},
      errArray: [],
      BatchName: '',
    };
  }

  handleForce = data => {
    this.setState({ data, BatchName: 'b-' + moment().format('DD-MM-YYYY-HH:mm') });
  };

  randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  startProcess = async () => {
    const { data, BatchName, minLng, maxLng, minLat, maxLat } = this.state;
    if (data.length == 0) {
      alert('load User Data');
      return;
    }
    if (!minLng || !maxLng || !minLat || !maxLat) {
      alert('Enter max and min for Location');
      return;
    }
    this.setState({ loadingIndex: 0 });
    for (var i = 0; i < data.length; i++) {
      let UserLocationLatitude = this.randomNumber(parseFloat(minLat), parseFloat(maxLat));
      let UserLocationLongitude = this.randomNumber(parseFloat(minLng), parseFloat(maxLng));
      var param = { ...data[i], BatchName, UserLocationLatitude, UserLocationLongitude };
      const formData = new FormData();
      for (var property in param) {
        formData.append(property, param[property]);
      }
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      await axios
        .post(URL + APIVersion + 'Users/BatchInsert', formData, config)
        .then(async resp => {
          if (resp.data.success) {
            this.setState({
              message: {
                ...this.state.message,
                [`${i}`]: 'Success',
              },
              loadingIndex: this.state.loadingIndex + 1,
            });
          }
        })
        .catch(error => {
          this.setState({
            message: {
              ...this.state.message,
              [`${i}`]: error.response.data.Message,
            },
            loadingIndex: this.state.loadingIndex + 1,
            errArray: [...this.state.errArray, { ...data[i], error: error.response.data.Message }],
          });
        });
    }
  };

  render() {
    const { data, loadingIndex, message, errArray, BatchName } = this.state;
    const inputs = [
      {
        name: 'Min lat',
        key: 'minLat',
      },
      {
        name: 'Max lat',
        key: 'maxLat',
      },
      {
        name: 'Min Lng',
        key: 'minLng',
      },
      {
        name: 'Max lat',
        key: 'maxLng',
      },
    ];
    return (
      <>
        <CSVReader
          cssClass="react-csv-input"
          label="Choose the file to extract places"
          onFileLoaded={this.handleForce}
          parserOptions={papaparseOptions}
        />
        {inputs.map(({ name, key }) => {
          return (
            <div>
              {name} :
              <input
                value={this.state[key]}
                onChange={e => {
                  this.setState({ [key]: e.target.value });
                }}
              />
            </div>
          );
        })}
        <button onClick={this.startProcess}>start Process</button>
        <br />
        BatchName: {BatchName}
        <br />
        <CSVLink data={errArray} separator={','}>
          download error after all the process is completed
        </CSVLink>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <Card style={{ width: 'max-content' }}>
                Name: {item.Name}
                <br />
                Age: {item.Age}
                <br />
                EmojiFlagUniCode: {item.EmojiFlagUniCode}
                <br />
                EmojiPartyAnimalUniCode: {item.EmojiPartyAnimalUniCode}
                <br />
                NativePlace: {item.NativePlace}
                <br />
                Sex: {item.Sex}
                <br />
                UserDescription: {item.UserDescription}
                <br />
                UserLevel: {item.UserLevel}
                <br />
                UserLocationLatitude: {item.UserLocationLatitude}
                <br />
                UserLocationLongitude: {item.UserLocationLongitude}
                <br />
                Interests: {item.Interests}
                <br />
                {loadingIndex == index && 'loading....'}
                <br />
                Message: {message[index]}
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  }
}

export default connect()(CsvUserCreate);
