import React, { PureComponent, useState } from 'react';
// import { ScouterEvent } from '../entityconfig';
import BaseView from '../BaseView';
//screen important
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux';
import { Actions } from '../../core/redux-helper';

const useStyles = makeStyles(theme => ({
  card: {
    margin: 10,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 'auto',
    height: 'auto',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

// const displayData = [
//   {
//     eventId: 'd10052c9-16cf-4048-a3bb-08d772698e58',
//     cityId: '6f7c219b-3699-4704-9ab8-6d6a20003c34',
//     placeId: '2348718f-48be-4d3e-b13d-0d794eb49d70',
//     eventTitle: 'Sexy Saturdays123',
//     eventDescription: '#Dresscode #Bachata #Latin',
//     eventImage:
//       'https://scouter-file2.s3.amazonaws.com/2348718f-48be-4d3e-b13d-0d794eb49d70/2019-11-23_22-31-49_UTC.jpg',
//     timeStamp: '2019-11-26T12:09:58.123',
//     eventDate: '2019-12-14T11:07:23.757',
//   },
//   {
//     eventId: 'fdbff3c0-8b8a-4869-a3bc-08d772698e58',
//     cityId: '6f7c219b-3699-4704-9ab8-6d6a20003c34',
//     placeId: '2348718f-48be-4d3e-b13d-0d794eb49d70',
//     eventTitle: 'Thanksgiving Party 123',
//     eventDescription: '#NoCover #Dresscode #Latin #House #R&B',
//     eventImage:
//       'https://scouter-file2.s3.amazonaws.com/2348718f-48be-4d3e-b13d-0d794eb49d70/2019-11-20_22-33-49_UTC.jpg',
//     timeStamp: '2019-11-26T12:13:25.66',
//     eventDate: '2019-12-09T11:28:05.177',
//   },
//   {
//     eventId: '4486d121-f28f-4481-a3bd-08d772698e58',
//     cityId: '6f7c219b-3699-4704-9ab8-6d6a20003c34',
//     placeId: '2348718f-48be-4d3e-b13d-0d794eb49d70',
//     eventTitle: 'Fridays After Work',
//     eventDescription: '#LetLoose #10cover #Latinmusic',
//     eventImage:
//       'https://scouter-file2.s3.amazonaws.com/2348718f-48be-4d3e-b13d-0d794eb49d70/2019-11-22_21-23-27_UTC.jpg',
//     timeStamp: '2019-11-26T12:14:54.26',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '7e3600c1-a1f0-41e9-9cb2-229081e8ba84',
//     cityId: null,
//     placeId: '1b68e816-8e85-4666-9ab4-59ae3aee2c9c',
//     eventTitle: 'Testing Drunk Bitch',
//     eventDescription: 'Testing Description',
//     eventImage: 'Testing Image',
//     timeStamp: '2019-09-17T12:51:46.76',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '4df29c9d-dff5-4235-adfa-3fbebe899ae3',
//     cityId: null,
//     placeId: 'b1afadec-4b6a-40c4-b476-dfb00ddecd73',
//     eventTitle: 'Desi Nite',
//     eventDescription: 'dESI NITE WITH dj manboo',
//     eventImage: null,
//     timeStamp: '2018-10-13T06:32:50.497',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '0d156cb5-d449-4709-b03c-51f28aee1c96',
//     cityId: null,
//     placeId: 'dbba0b14-467e-4287-bf8c-d1cb7aa258bc',
//     eventTitle: 'Testing Event',
//     eventDescription: 'This event is for testing purposes',
//     eventImage: 'This is the image',
//     timeStamp: '2019-08-21T09:40:31.397',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '0d8e3ad7-d28b-43ec-9ed5-526f04a6aceb',
//     cityId: '85ab5e34-3d98-406f-a8c1-77df8ed68c2c',
//     placeId: 'f183104d-481e-440c-9aa7-099c5ece5ca0',
//     eventTitle: 'Young THICK LATINAS, YELLOWBONES #FREEENTRY',
//     eventDescription:
//       'HAPPY NEW YEAR LETS START OF 2020 THE RIGHT WAY!!!! ?? • COME CELEBRATE WITH US?? DM FOR FREE BIRTHDAY SECTIONS & BOTTLE! ???? • LADIES IN GROUPS OF 5 OR MORE RSVP NOW FOR: ?FREE SECTION ?FREE BOTTLE & more.... • • Baddies & Bottles ???? Gossip Bar & Lounge 5481 Memorial Drive Stone Mountain GA 30083 • • ??Ladies Drink FREE til 11PM?? ??$100 BOTTLES UNTIL 1AM?? ??DRINK SPECIALS TIL MIDNIGHT?? • • ?? FREE ENTRY ALL NIGHT?????? ?? FREE ENTRY ALL NIGHT?????? ?? FREE ENTRY ALL NIGHT?????? • • FOLLOW @baddiesandbottles FOR MORE INFO?? • • #reggae #soca #randb #fete #hiphop #dancehall #afrobeats #atlantadancehall #atlantanightlife #dayparty #atldayparty #atlnightlife #atlanta #atl #atlantanightlife #baddiesandbottles #NewYearsParty #baddiesandbottlesatl #freeallnight #NewYearsDay #NYE2020 #FirstDayOut',
//     eventImage: 'https://scouter-file2.s3.amazonaws.com/B6uMS15g01M.jpg',
//     timeStamp: '2019-12-31T16:01:45.473',
//     eventDate: '2019-01-01T00:00:00',
//   },
//   {
//     eventId: '52a0f957-8dff-476a-9890-5564a9fb3707',
//     cityId: null,
//     placeId: 'b2e275cb-5019-4841-b558-85ed29ba905b',
//     eventTitle: 'Clout Fridays at Lavoo Lounge | $100 Bottles',
//     eventDescription:
//       'Everybody on the Guest List FREE Before 12am and REDUCED afterwards w/ RSVP. $100 Bottles B4 11PM 2 Bottle Minimum',
//     eventImage: 'Looking real good',
//     timeStamp: '2019-09-04T17:08:23.56',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '0ef9c62a-266d-48f3-89a4-73aa16e1eafe',
//     cityId: null,
//     placeId: '2850939c-e279-4458-9cdd-d2acb9f083bd',
//     eventTitle: 'RAP Nite',
//     eventDescription: 'RAP DJ Nite',
//     eventImage: null,
//     timeStamp: '2018-10-13T06:32:18.43',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '6a316770-0aca-47cd-9140-96f3d7c354fc',
//     cityId: null,
//     placeId: '9ad70082-750e-4954-ab82-6acc09822737',
//     eventTitle: 'h',
//     eventDescription: 'h',
//     eventImage: 'C:\\fakepath\\heart-700141_960_720.jpg',
//     timeStamp: '2019-09-30T06:47:30.703',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: 'b524fe03-e5fe-4f9d-a551-9a0b22d76fef',
//     cityId: '85ab5e34-3d98-406f-a8c1-77df8ed68c2c',
//     placeId: '99e756b1-7e6e-40cf-9dd2-0eeb9cddd061',
//     eventTitle: "New Year's Eve Bash @Opiumatl",
//     eventDescription:
//       "WHERE WILL YOU BRING IN THE NEW YEAR?? WE'RE MAKING A MOVIE @opiumatl FOR NEW YEARS EVE TOMORROW ???????? . . TEXT #NYE TO ?? 404-488-8513 FOR VIP TABLES, THERES ONLY A FEW LEFT (LINK IN BIO FOR TICKETS)??? #NEWYEARSEVE #NEWYEARSEVEATL #NYEATLANTA #NYEATL #OPIUMSATURDAYS #ATLANTA #ATL  #ATLANTANIGHTLIFE #atlnights",
//     eventImage: 'https://scouter-file2.s3.amazonaws.com/B6uF5qNBWU7.jpg',
//     timeStamp: '2019-12-31T16:02:30.813',
//     eventDate: '2019-01-01T00:00:00',
//   },
//   {
//     eventId: 'f5b40a44-15b6-4056-9009-9aa2231c7799',
//     cityId: null,
//     placeId: 'c618e5b5-9b52-4c61-b83b-1d68480a7fda',
//     eventTitle: 'ccccc',
//     eventDescription: 'cccc',
//     eventImage:
//       'https://media.istockphoto.com/photos/heart-shape-paper-book-on-the-beach-picture-id817147678?k=6&m=817147678&s=612x612&w=0&h=vNJJJoRPaieom61bMwQxaGtOImMhJsoxz1OIgLZNOEw=',
//     timeStamp: '2019-09-28T11:43:25.367',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: 'a8cb015e-f5ac-494a-b829-a1a44e391ddf',
//     cityId: '85ab5e34-3d98-406f-a8c1-77df8ed68c2c',
//     placeId: '64139f8c-ffbe-44c7-80e7-47881c3a69a4',
//     eventTitle: 'NYE ARAB STYLE #hookah #paytoplay',
//     eventDescription:
//       '? ATL join us for NYE @addis lounge formerly NU ??? champagne toast at midnight.  Early arrival is a must . For vip section Call/text 4049365959  Free Entrance for everyone!!! DOORS OPEN 9PM • • • • ?? Sound by  @djhabtesh • • • #addislounge #newyeareveatl#allblackaffair #habeshanewyearatl#habeshanight #habeshanewyeareve#Atlnightlife #habeshavip #ethiopia????#eritrea???? #nightclub #party #atlparties #atlmodels #atlnightlife #habeshaatl #Atlhabesha #hookah #atl #habeshanight #addis#atlantanightlife #habeshaallblackaffair #NewYearEveCelebration #addislounge',
//     eventImage: 'https://scouter-file2.s3.amazonaws.com/B6t0P1rHS0R.jpg',
//     timeStamp: '2019-12-31T16:02:44.113',
//     eventDate: '2019-01-01T00:00:00',
//   },
//   {
//     eventId: '1b0ae4eb-1fe9-4d02-a183-a6d4093b23dd',
//     cityId: '85ab5e34-3d98-406f-a8c1-77df8ed68c2c',
//     placeId: '2c405bac-376f-4687-949c-944b57483dc9',
//     eventTitle: '$50 for 30 mins, $100 for the hour',
//     eventDescription:
//       "Hmmmm I don't take bathroom pics BUT I am today ??????..... Come pull up on me for old school Monday ?????????? #locsofcigars #locnation #cigarenthusiast #cigarsnob #cigar #melanincigarfetish #blackcigarsmokers #Southside #cigar #cigarsociety #cigarsmokers #cigaraficionados #atlanta #cigarporn #cigaradventurer #atlantanightlife #collegepark",
//     eventImage: 'https://scouter-file2.s3.amazonaws.com/B6t06iznPB3.jpg',
//     timeStamp: '2019-12-31T16:02:04.173',
//     eventDate: '2019-01-01T00:00:00',
//   },
//   {
//     eventId: 'ffd8b71d-416d-4971-8b5e-a90b42f949ed',
//     cityId: null,
//     placeId: '2d5b145e-0556-4a51-9c87-892e58f5387b',
//     eventTitle: 'gg',
//     eventDescription: 'sdf',
//     eventImage: 'heart.jpg',
//     timeStamp: '2019-10-03T11:39:59.64',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '352a7f2a-8a76-4421-9fd5-b1a0d8b2b975',
//     cityId: '85ab5e34-3d98-406f-a8c1-77df8ed68c2c',
//     placeId: 'c8166d8a-8f29-4c83-82f4-584e332e9da6',
//     eventTitle: 'Chill NYE pizza party good for lazy people',
//     eventDescription:
//       '#NYE2020 at @BakarisPizza ???? FREE ENTRY + FREE PARKING • Party From 9PM Until Vibes End • DM For VIP Bottle Service • #ATLBars #ATLLounge #ATLLounges #AtlantaLounge #AtlantaLounges #ATLNights #ATLNightlife #AtlantaNights #AtlantaNightlife #ATLPizza #ATLHappyHour #AtlantaHappyHour #ATLNightspots #ATLFoodie #AtlantaFoodie #ATLVegan #AtlantaVegan #PizzaLounge #VeganAtlanta #VeganNightlife #WestEndATL #SouthwestAtlanta #TheSocialGroupATL #WZDMATL #PowerPlayConnects #BakarisLounge #BakarisPizza #BakarisFridays #NYE',
//     eventImage: 'https://scouter-file2.s3.amazonaws.com/B6t3OX5gdEw.jpg',
//     timeStamp: '2019-12-31T16:02:52.82',
//     eventDate: '2019-01-01T00:00:00',
//   },
//   {
//     eventId: '224c84aa-1ed8-4b49-88ca-bc4331358d0e',
//     cityId: null,
//     placeId: '2d5b145e-0556-4a51-9c87-892e58f5387b',
//     eventTitle: 'uuuu',
//     eventDescription: 'yuyuy',
//     eventImage:
//       'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBxISEhURExMVFRUVFxYXGBYVFxcWFxYYFhUXFxgVFxkYHSggGRolGxcVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDg0OGxAQGy0lICUtLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLf/AABEIAMIBAwMBEQACEQEDEQH/xAAbAAEAAgMBAQAAAAAAAAAAAAAABAUCAwYBB//EADwQAAEDAgQDBgQEBAUFAAAAAAEAAhEDIQQSMVEFQWETInGBkaEGMrHwQlLB0SNi4fEHFHKCojRzkrLC/8QAGwEBAAMBAQEBAAAAAAAAAAAAAAECAwQFBgf/xAAvEQEAAgIBAwMDAgYDAQEAAAAAAQIDESEEEjEFQVETImFxkQYUMoGh8LHB0eEj/9oADAMBAAIRAxEAPwD5m',
//     timeStamp: '2019-09-30T07:21:50.987',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: 'd7a14a20-4a53-4c8f-beec-c365d2f51fff',
//     cityId: null,
//     placeId: 'b1afadec-4b6a-40c4-b476-dfb00ddecd73',
//     eventTitle: 'sdfs',
//     eventDescription: 'sdfsd',
//     eventImage: 'sdf',
//     timeStamp: '2019-09-30T05:20:15.157',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: 'afe79f0b-1b56-43a1-b129-c66e62373d8c',
//     cityId: null,
//     placeId: 'b2e275cb-5019-4841-b558-85ed29ba905b',
//     eventTitle: 'Testing antD event',
//     eventDescription: 'See if this populates',
//     eventImage: 'Testing Image',
//     timeStamp: '2019-09-17T12:35:31.863',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '8dfe9b30-e0ca-4d35-8ed5-e4bccd63ae80',
//     cityId: null,
//     placeId: '1b68e816-8e85-4666-9ab4-59ae3aee2c9c',
//     eventTitle: 'Liven DJ',
//     eventDescription: 'test',
//     eventImage:
//       'https://media.istockphoto.com/photos/club-disco-dj-playing-and-mixing-music-for-people-nightlife-picture-id693589852?k=6&m=693589852&s=612x612&w=0&h=ur3EnkD6tvUA2QqQdLp8eqxx8WEg6b6aoibpq_R2Ku4=',
//     timeStamp: '2018-10-13T06:30:58.103',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: 'ea9914d1-6b62-4d76-b33c-f31f0bd5652e',
//     cityId: null,
//     placeId: '2d5b145e-0556-4a51-9c87-892e58f5387b',
//     eventTitle: 'test',
//     eventDescription: 'test1',
//     eventImage:
//       'https://media1.tenor.com/images/b440fe8b087416820258b711b91ca18a/tenor.gif?itemid=9499692',
//     timeStamp: '2019-09-30T10:34:01.957',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: 'e1f77835-9700-4939-a65f-f5c025066cfc',
//     cityId: null,
//     placeId: '212314c4-abe8-4c85-9b03-8fb176f56972',
//     eventTitle: 'ROCKIN’ WINGS',
//     eventDescription:
//       'Jumbo wings, marinated with our signature smoked dry rub, slow roasted and served with Blue Cheese and carrot. Choice of dry seasoned or Buffalo Style with our Classic Rock or Heavy Metal sauces.',
//     eventImage: null,
//     timeStamp: '2018-10-16T06:10:50.463',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
//   {
//     eventId: '1698f061-4dc9-4c1a-9cd2-f9bc6d742fd2',
//     cityId: null,
//     placeId: '2d5b145e-0556-4a51-9c87-892e58f5387b',
//     eventTitle: 'testing data',
//     eventDescription: 'testing thie nightclub',
//     eventImage: 'sadfsfd',
//     timeStamp: '2019-09-27T10:02:42.347',
//     eventDate: '2019-12-07T11:06:55.317',
//   },
// ];

function ImgMediaCard({ events }) {
  const classes = useStyles();
  const a = {};
  // displayData.map(item => (a[item.eventId] = false));
  const [selectedEvents, setSelectedEvents] = useState(a);

  return (
    <>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => {
            alert(
              Object.keys(selectedEvents)
                .filter(item => selectedEvents[item])
                .toString(),
            );
          }}
        >
          Delete
        </Button>
      </ButtonGroup>
      <GridList cellHeight={300} spacing={5} className={classes.gridList} cols={4}>
        {events &&
          events.map(item => {
            return (
              <GridListTile key={item.EventImage} cols={1} rows={1}>
                <img alt={item.alt} src={item.EventImage} title={item.EventTitle} />
                <GridListTileBar
                  title={item.EventTitle}
                  subtitle={<span>by: {item.EventTitle}</span>}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${item.EventTitle}`}
                      className={classes.icon}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
                <GridListTileBar
                  titlePosition="top"
                  title={item.EventTitle}
                  subtitle={<span>by: {item.EventTitle}</span>}
                  actionIcon={
                    <Checkbox
                      onChange={event => {
                        setSelectedEvents({
                          ...selectedEvents,
                          [item.EventId]: event.target.checked,
                        });
                      }}
                      value="primary"
                    />
                  }
                />
              </GridListTile>
            );
          })}
      </GridList>
    </>
  );
}

class ScouterEventView extends BaseView {
  constructor(props) {
    super({ props });
    // this.config = this.constructConfig(ScouterEvent);
  }
  componentDidMount() {
    this.props.dispatch(
      Actions['list10055']({
        pageNo: 0,
        apiIdentifier: 'Event',
        identifier: 'event',
        action: 'list',
        pageSize: 50,
        filterInfo: null,
        sortInfo: null,
        comboTypes: [
        {
            IDField:  "CityId",
            ValueField: "CityName",
            type: "10051"
        },
        {
            IDField:  "PlaceId",
            ValueField: "PlaceName",
            type: "10057"
        }
        ],
      }),
    );
  }
  render() {
    const { events } = this.props;
    return <ImgMediaCard events={events} />;
  }
}

const mapStateToProps = ({list_10055}) => {
  let events;
  if (list_10055 && list_10055.data) {
    events = list_10055.data.data;
  }
  return {
    events,
  };
};

export default connect(mapStateToProps)(ScouterEventView);
