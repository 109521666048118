import { Actions } from '../core/redux-helper';
import { defaultGridColumns } from '../core/utils';

export const SurveyDetail = {
  key: 'SurveyDetail',
  title: 'SurveyDetail',
  idColumn: 'SurveyDetailId',
  listAPI: Actions.listSurveyDetail,
  getGridColumns: () => [
    {
      dataIndex: 'SurveyDetailId',
      header: 'Survey Detail',
    },
    {
      dataIndex: 'SurveyQuestionId',
      header: 'Survey Question',
      type: 'combo',
      comboType: 'SurveyQuestionId',
    },
    {
      dataIndex: 'Response',
      header: 'Response',
    },
    {
      dataIndex: 'AssignedUserId',
      header: 'Assigned User',
      type: 'combo',
      comboType: 'AssignedUserId',
    },

    ...defaultGridColumns(),
  ],
  getFormColumns: () => [
    {
      dataIndex: 'SurveyQuestionId',
      header: 'Survey Question',
      type: 'combo',
      comboType: 'SurveyQuestionId',
    },
    {
      dataIndex: 'Response',
      header: 'Response',
    },
    {
      dataIndex: 'AssignedUserId',
      header: 'Assigned User',
      type: 'combo',
      comboType: 'AssignedUserId',
    },
  ],
  childs: () => [],
  comboTypes: [
    {
      type: 'SurveyQuestionId',
      ValueField: 'Name',
      IDField: 'SurveyQuestionId',
    },
    {
      type: 'AssignedUserId',
      ValueField: 'Name',
      IDField: 'AssignedUserId',
    },
  ],
};
