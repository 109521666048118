import React, { PureComponent } from 'react';
import { ControlRoomRequestReply } from '../entityconfig';
import BaseView from './BaseView';

class ControlRoomRequestReplyView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(ControlRoomRequestReply);
  }
}

export default ControlRoomRequestReplyView;
