import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HashRouter, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';
import Login from './Screens/login';
import Dashboard from './layouts/Admin';
// import Login from './layouts/Auth'
import { Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.css';
import './App.css';
import './app.scss';
import './resizeble.css';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0';
import 'antd/dist/antd.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import SwaggerUI from './Screens/SwaggerUI';

import { GoogleOAuthProvider } from '@react-oauth/google';

const checkAuthorization = () => {
  const storedToken = localStorage.getItem('cube:token');

  if (storedToken) {
    return true;
  } else {
    return false;
  }
};
const AuthenticatedRoute = ({ component: DynamicComponent, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (checkAuthorization()) {
        return <DynamicComponent {...props} />;
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <GoogleOAuthProvider clientId="241532746039-98ce7g359os2qcrp8b7oao9rvn752d5o.apps.googleusercontent.com">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <HashRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/SwaggerUI" component={SwaggerUI} />
              <Route exact path="/login" component={Login} />
              {/* <AuthenticatedRoute component={Dashboard} /> */}
              {checkAuthorization() ? <Dashboard /> : <></>}
            </Switch>
          </HashRouter>
        </Provider>
      </MuiPickersUtilsProvider>
    </GoogleOAuthProvider>
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
