import React from 'react';
// material
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Button,
  CardActions,
  List,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItem,
} from '@material-ui/core';

// ----------------------------------------------------------------------

export default function AppWebsiteVisits() {
  return (
    <Card style={{ borderRadius: '15px' }}>
      <CardActionArea>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <React.Fragment>
                  Volunteer
                  <Typography component="div" variant="body2" color="textPrimary">
                    Risk Control
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: 'blue', marginTop: '15px' }}
                    component="p"
                  >
                    5 minutes ago
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
          </ListItem>
        </List>
        <CardContent style={{ marginTop: '-10px' }}>
          <Typography variant="body2" color="textSecondary" component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
            across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          style={{ backgroundColor: 'pink', borderRadius: '25px', padding: '5px 15px' }}
        >
          Pending
        </Button>
        <Button size="small" color="dark" style={{ marginLeft: 'auto', textTransform: 'none' }}>
          Hafeezpet
        </Button>
      </CardActions>
    </Card>
  );
}
