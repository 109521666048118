import { Actions } from '../core/redux-helper';

export const Activity = {
  key: 'Activity',
  title: 'Activity',
  idColumn: 'ActivityId',
  listAPI: Actions.listActivity,
  getGridColumns: () => [
    {
      dataIndex: 'ActivityId',
      header: 'ActivityId',
    },
    {
      dataIndex: 'ActivityTypeId',
      header: 'Activity Type',
      type: 'combo',
      comboType: 'ActivityType',
    },
    {
      dataIndex: 'Text',
      header: 'Text',
    },
    {
      dataIndex: 'Date',
      header: 'Date',
      type: 'date',
    },
    {
      dataIndex: 'Address',
      header: 'Address',
    },
    {
      dataIndex: 'InLocation',
      header: 'InLocation',
      type: 'boolean',
    },
    {
      dataIndex: 'AttachmentURL',
      header: 'AttachmentURL',
    },
    {
      dataIndex: 'AttactmentThumbNail',
      header: 'AttactmentThumbNail',
    },
    {
      dataIndex: 'AttachmentTypeId',
      header: 'AttachmentTypeId',
      type: 'combo',
      comboType: 'AttachmentType',
    },
    {
      dataIndex: 'LikeCount',
      header: 'LikeCount',
      type: 'number',
    },
    {
      dataIndex: 'DislikeCount',
      header: 'DislikeCount',
      type: 'number',
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'number',
      renderer: 'C8',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
    {
      dataIndex: 'CityId',
      header: 'CityId',
      type: 'combo',
      comboType: 'City',
    },
    {
      dataIndex: 'UserId',
      header: 'UserId',
      type: 'combo',
      comboType: 'User',
    },
    {
      dataIndex: 'ViewCount',
      header: 'ViewCount',
      type: 'number',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
    {
      dataIndex: 'Date',
      header: 'Date',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'ActivityTypeId',
      header: 'Activity Type',
      type: 'combo',
      comboType: 'ActivityType',
      isRequired: true,
    },
    {
      dataIndex: 'Text',
      header: 'Text',
    },
    {
      dataIndex: 'Address',
      header: 'Address',
      type: 'textarea',
      rowSpan: 2,
      height: 'auto',
      disableUpdate: true,
    },

    {
      dataIndex: 'AttachmentURL',
      header: 'AttachmentURL',
      type: 'textarea',
      rowSpan: 2,
      height: 'auto',
      disabled: true,
    },

    {
      dataIndex: 'AttachmentTypeId',
      header: 'AttachmentTypeId',
      type: 'combo',
      comboType: 'AttachmentType',
      isRequired: true,
      disableUpdate: true,
    },
    {
      dataIndex: 'LikeCount',
      header: 'LikeCount',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'DislikeCount',
      header: 'DislikeCount',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'Latitude',
      header: 'Latitude',
      type: 'float',
    },
    {
      dataIndex: 'Longitude',
      header: 'Longitude',
      type: 'float',
    },
    {
      dataIndex: 'CityId',
      header: 'CityId',
      type: 'combo',
      comboType: 'City',
    },
    {
      dataIndex: 'ViewCount',
      header: 'ViewCount',
      type: 'int',
      isRequired: true,
    },
    {
      dataIndex: 'Date',
      header: 'Date',
      type: 'date',
    },
    {
      dataIndex: 'InLocation',
      header: 'InLocation',
      type: 'boolean',
      isRequired: true,
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },

    {
      dataIndex: 'AttactmentThumbNail',
      header: 'AttactmentThumbNail',
      type: 'imageUpload',
      colSpan: 24,
      height: 'auto',
    },
  ],
  getCardView: {
    title: 'ActivityTypeName',
    type: '',
    thumbImg: 'AttactmentThumbNail',
    time: 'ModifiedDate',
    description: 'Text',
  },
  comboTypes: [
    {
      type: 'ActivityType',
      ValueField: 'ActivityTypeName',
      IDField: 'ActivityTypeId',
      AdditionalFields: ', IsControl, Type, Marker',
    },
    {
      type: 'AttachmentType',
      ValueField: 'AttachmentTypeName',
      IDField: 'AttachmentTypeId',
    },
    {
      type: 'City',
      ValueField: 'CityName',
      IDField: 'CityId',
    },
  ],
  childs: () => [],
};
