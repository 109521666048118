import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListIcon from '@material-ui/icons/List';
import Fab from '@material-ui/core/Fab';
import AppsIcon from '@material-ui/icons/Apps';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

export class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: false,
      sortMode: 'newest',
      pendingCheckedA: true,
      pendingCheckedB: true,
      activeCheckedA: true,
      activeCheckedB: true,
      closedCheckedA: true,
      closedCheckedB: true,
    };
  }

  handleChange = event => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  onSelectChange = value => {
    this.setState({ sortMode: value });
  };

  showModal = () => {
    this.setState({ open: true });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography
              variant="caption"
              gutterBottom
              style={{ textTransform: 'none', fontWeight: 'bold' }}
            >
              {t('Showing 243 Activity Results')}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {t('Based on your preferences')}
            </Typography>
          </Grid>
          <Grid item container xs={4} direction="row" justifyContent="center" alignItems="center">
            <div className="flex-container">
              <Typography variant="caption">{t('Pending')}</Typography>
              <Switch
                checked={this.state.pendingCheckedB}
                onChange={this.handleChange}
                color="primary"
                name="pendingCheckedB"
              />
            </div>
            <div className="flex-container">
              <Typography variant="caption">{t('Active')}</Typography>
              <Switch
                checked={this.state.activeCheckedB}
                onChange={this.handleChange}
                color="primary"
                name="activeCheckedB"
              />
            </div>
            <div className="flex-container">
              <Typography variant="caption">{t('Closed')}</Typography>
              <Switch
                checked={this.state.closedCheckedB}
                onChange={this.handleChange}
                color="primary"
                name="closedCheckedB"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Select
              defaultValue="Hyderabad"
              value={this.state.sortMode}
              className="sortModeContainer"
              onChange={e => {
                this.onSelectChange(e);
              }}
            >
              <Option value="newest">
                <span style={{ fontSize: 12 }}>{t('Newest')}</span>
              </Option>
              <Option value="oldest">
                <span style={{ fontSize: 12 }}>{t('Oldest')}</span>
              </Option>
              <Option value="mostLiked">
                <span style={{ fontSize: 12 }}>{t('Most Liked')}</span>
              </Option>
            </Select>
            <div>
              <Fab
                size="small"
                onClick={() => {
                  this.setState({ viewMode: false });
                  this.props.customViewMode(true);
                }}
                style={{ marginRight: 5 }}
                className={this.state.viewMode === false ? 'activeView' : 'inactiveView'}
              >
                <AppsIcon
                  className={this.state.viewMode === false ? 'activeViewIcon' : 'inactiveViewIcon'}
                />
              </Fab>
              <Fab
                size="small"
                onClick={() => {
                  this.setState({ viewMode: true });
                  this.props.customViewMode(false);
                }}
                className={this.state.viewMode === true ? 'activeView' : 'inactiveView'}
              >
                <ListIcon
                  className={this.state.viewMode === true ? 'activeViewIcon' : 'inactiveViewIcon'}
                />
              </Fab>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withTranslation()(Filters);
