import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { backButtonVisibility, titleVisibility } from '../app-config';
import { breadcrumbsVisibility } from '../app-config';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import routes from 'routes.js';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx';

import image from 'assets/img/sidebar-2.jpg';
// import logo from 'assets/img/iRed.png';
import logoMiniSid from 'assets/img/Logo.png';
import { Card, CardContent, Typography, CardActionArea, Box, CardActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { disableRippleEffect, openInNewTabButton } from '../app-config/index';
import { withRouter } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
var ps;

const CustomCardActionArea = withStyles({
  focusHighlight: {
    backgroundColor: 'white',
    '&:hover': {
      opacity: 0,
    },
  },
})(props => <CardActionArea {...props} />);

const checkAuthorization = () => {
  const storedToken = localStorage.getItem('cube:token');

  if (storedToken) {
    return true;
  } else {
    return false;
  }
};

const AuthenticatedRoute = ({ component: DynamicComponent, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (checkAuthorization()) {
        return <DynamicComponent {...props} />;
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: 'blue',
      bgColor: 'black',
      hasImage: true,
      fixedClasses: 'dropdown',
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }

    window.addEventListener('resize', this.resizeFunction);
    let items = JSON.parse(localStorage.languageData);
    let engTran = {};
    let spnTran = {};
    let ptTran = {};
    let araTran = {};
    let cnTran = {};
    for (let i = 0; i < items.length; i++) {
      let currentRecord = items[i];
      let currKey = currentRecord['OrigText'];
      engTran[currKey] = currentRecord['EngText'];
      spnTran[currKey] = currentRecord['SpainText'];
      ptTran[currKey] = currentRecord['PortText'];
      araTran[currKey] = currentRecord['ArabicText'];
      cnTran[currKey] = currentRecord['ChineseText'];
    }
    this.props.i18n.addResourceBundle('en', 'translations', engTran, true, true);
    this.props.i18n.addResourceBundle('es', 'translations', spnTran, true, true);
    this.props.i18n.addResourceBundle('pt', 'translations', ptTran, true, true);
    this.props.i18n.addResourceBundle('ar', 'translations', araTran, true, true);
    this.props.i18n.addResourceBundle('cn', 'translations', cnTran, true, true);
    let currentLanguage = localStorage.selectedLanguage || 'en';

    this.props.i18n.changeLanguage(currentLanguage);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' });
    } else {
      this.setState({ fixedClasses: 'dropdown' });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== '/admin/full-screen-maps';
  }
  getActiveRoute = (routes, withSubText, hideHeader) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].views) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views, withSubText, hideHeader);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (this.props.location.pathname == routes[i].path) {
          if (hideHeader) {
            return routes[i].hideHeader;
          }
          return routes[i].headerSubText && withSubText
            ? routes[i].name + '  ' + routes[i].headerSubText
            : routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === 'dashboard') {
        return (
          <AuthenticatedRoute path={prop.path} component={prop.component} key={key} exact={true} />
        );
      } else {
        return null;
      }
    });
  };

  getCardRoutes = routes => {
    let arr = [];
    routes.map((prop, i) => {
      if (this.props.location.pathname == prop.path) {
        if (prop.views) arr.push(...prop.views);
      }
    });
    return arr;
  };

  getViewRoutes = routes => {
    let arr = [];
    routes.map((prop, i) => {
      if ('/' + this.props.location.pathname.split('/')[1] == prop.path) {
        if (prop.views) arr.push(...prop.views);
      }
    });
    return arr;
  };

  getBreadCrumHeader = routes => {
    let name = '';
    const style = { color: 'black', paddingRight: 3, fontSize: '15px' };
    for (let i = 0; i < routes.length; i++) {
      if ('/' + this.props.location.pathname.split('/')[1] == routes[i].path) {
        if (routes[i].views) {
          for (let j = 0; j < routes[i].views.length; j++) {
            if ('/' + this.props.location.pathname.split('/')[2] == routes[i].views[j].path) {
              name = (
                <>
                  {breadcrumbsVisibility ? (
                    <>
                      <Link to={routes[i].path} {...{ style }}>
                        {routes[i].name}
                      </Link>
                      <div {...{ style }}>{'/    ' + routes[i].views[j].name}</div>
                    </>
                  ) : null}
                </>
              );
            }
          }
        }
        if (!name) name = <div {...{ style }}>{breadcrumbsVisibility ? routes[i].name : null}</div>;
      }
    }
    return name;
  };

  getHeader = (routes, isHideHeader) => {
    let name = '';
    this.getViewRoutes(routes).map((prop, i) => {
      var a = this.props.location.pathname.split('/');
      if ('/' + a[a.length - 1] == prop.path) {
        if (isHideHeader && prop.hideHeader) name = null;
        else name = prop.name;
      }
    });
    return name;
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, t, ...rest } = this.props;

    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      });

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={t('Admin Portal')}
          // logo={logo}
          logoMiniSid={logoMiniSid}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div
          className={mainPanel}
          style={{ height: '100vh', backgroundColor: '#24B5EE' }}
          ref="mainPanel"
        >
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getBreadCrumHeader(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <>
              {this.getHeader(routes, true) ? (
                titleVisibility && (
                  <div className={classes.activeheader}>
                    <span style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
                      {backButtonVisibility ? (
                        <ArrowBackIosIcon onClick={() => this.props.history.goBack()} />
                      ) : null}
                    </span>
                    <span style={{ paddingLeft: '10px' }}>{this.getHeader(routes)}</span>
                  </div>
                )
              ) : (
                <div style={{ marginTop: '0px' }}></div>
              )}
              <div className={classes.content}>
                <div className={classes.container}>
                  <div>
                    {this.getCardRoutes(routes).map(item => {
                      return (
                        item &&
                        !item.hideCard && (
                          <>
                            <Link to={item.parentPath + item.path}>
                              <Card
                                style={{
                                  width: 332,
                                  display: 'inline-block',
                                  margin: '15px',
                                  height: 150,
                                }}
                              >
                                {disableRippleEffect ? (
                                  <>
                                    <CardContent>
                                      <Typography variant="button" display="block">
                                        <Box
                                          m={1}
                                          fontFamily="Inter"
                                          style={{
                                            textTransform: 'capitalize',
                                            fontSize: '20px',
                                            letterSpacing: 'initial',
                                          }}
                                        >
                                          {t(item.name)}
                                        </Box>
                                      </Typography>
                                      <Typography
                                        style={{ lineHeight: '1.5' }}
                                        variant="overline"
                                        display="block"
                                        gutterBottom
                                        color="textSecondary"
                                      >
                                        <Box
                                          m={1}
                                          fontFamily="Inter"
                                          // fontStyle="oblique"
                                          style={{
                                            textTransform: 'initial',
                                            fontSize: '14px',
                                            letterSpacing: 'initial',
                                            color: 'rgba(0, 0, 0, 0.87)',
                                          }}
                                        >
                                          Review the {item.cardText || item.name + 's'} imported in
                                          the system
                                        </Box>
                                      </Typography>
                                    </CardContent>
                                    {openInNewTabButton ? (
                                      <CardActions disableSpacing>
                                        <IconButton
                                          size="medium"
                                          color="textSecondary"
                                          style={{
                                            marginLeft: 'auto',
                                            top: -32,
                                            // right: 62,
                                            position: 'relative',
                                          }}
                                        >
                                          <OpenInNewIcon
                                            onClick={e => {
                                              window.open(
                                                '#' + item.parentPath + item.path,
                                                '_blank',
                                              );
                                              e.preventDefault();
                                            }}
                                          />
                                        </IconButton>
                                      </CardActions>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    <CustomCardActionArea>
                                      <CardContent>
                                        <Typography variant="button" display="block">
                                          <Box
                                            m={1}
                                            fontFamily="Inter"
                                            style={{
                                              textTransform: 'capitalize',
                                              fontSize: '20px',
                                            }}
                                          >
                                            {t(item.name)}
                                          </Box>
                                        </Typography>
                                        <Typography
                                          style={{ lineHeight: '1.5' }}
                                          variant="overline"
                                          display="block"
                                          gutterBottom
                                          color="textSecondary"
                                        >
                                          <Box
                                            m={1}
                                            fontFamily="Inter"
                                            // fontStyle="oblique"
                                            style={{ textTransform: 'initial', fontSize: '14px' }}
                                          >
                                            Review the {t(item.cardText) || t(item.name) + 's'}{' '}
                                            imported in the system
                                          </Box>
                                        </Typography>
                                      </CardContent>
                                      {openInNewTabButton ? (
                                        <CardActions disableSpacing>
                                          <IconButton
                                            size="medium"
                                            color="textSecondary"
                                            style={{
                                              marginLeft: 'auto',
                                              top: -32,
                                              // right: 62,
                                              position: 'relative',
                                            }}
                                          >
                                            <OpenInNewIcon
                                              onClick={e => {
                                                window.open(
                                                  '#' + item.parentPath + item.path,
                                                  '_blank',
                                                );
                                                e.preventDefault();
                                              }}
                                            />
                                          </IconButton>
                                        </CardActions>
                                      ) : null}
                                    </CustomCardActionArea>
                                  </>
                                )}
                              </Card>
                            </Link>
                          </>
                        )
                      );
                    })}
                  </div>

                  <Switch>
                    {this.getViewRoutes(routes).map((prop, key) => {
                      return (
                        <Route
                          path={prop.parentPath + prop.path}
                          render={props => (
                            <prop.component miniActive={this.state.miniActive} {...props} />
                          )}
                          key={key}
                          exact={true}
                        />
                      );
                    })}
                    {routes.map((prop, key) => {
                      if (!prop.views) {
                        return (
                          <Route
                            path={prop.path}
                            render={props => (
                              <prop.component miniActive={this.state.miniActive} {...props} />
                            )}
                            key={key}
                            exact={true}
                          />
                        );
                      }
                    })}
                  </Switch>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(appStyle)(withRouter(Dashboard)));
