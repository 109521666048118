import React, { PureComponent } from 'react';
import { WellnessCenter } from '../entityconfig';
import BaseView from './BaseView';

class WellnessCenterView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(WellnessCenter);
  }
}

export default WellnessCenterView;
