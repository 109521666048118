// material
import React from 'react';
import { Box, Grid, Container, Typography, Card, Button } from '@material-ui/core';
import Scorecard from '../components/Dashboard/ScoreCard';
import ControlRoom from '../components/Dashboard/ControlRoom';
// components
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import Activitystatus from '../components/Dashboard/ActivityStatus';
import RecentActivity from '../components/Dashboard/RecentActivity';
import Volunteers from '../components/Dashboard/Volunteer';
import Carousel, { consts } from 'react-elastic-carousel';
import { withTranslation } from 'react-i18next';

import { injectTOStore } from 'core/redux-helper';
import { newConfig } from 'store/config';
// ----------------------------------------------------------------------

injectTOStore(newConfig);

class DashboardApp extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <Container maxWidth="xl">
        <Grid container spacing={3} style={{ marginTop: '15px' }}>
          <Grid item xs={12} sm={6} md={3}>
            <Scorecard
              icon={<TodayOutlinedIcon style={{ color: 'white', fontSize: '35px' }} />}
              title={t('Scheduled Todos')}
              score="86"
              bgColor="#4e36e2"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Scorecard
              icon={<TodayOutlinedIcon style={{ color: 'white', fontSize: '35px' }} />}
              title={t('Activities Added')}
              score="75"
              bgColor="#48a9f9"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Scorecard
              icon={<TodayOutlinedIcon style={{ color: 'white', fontSize: '35px' }} />}
              title={t('Registered Users')}
              score="551"
              bgColor="#1ccf85"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Scorecard
              icon={<TodayOutlinedIcon style={{ color: 'white', fontSize: '35px' }} />}
              title={t('Complaints')}
              score="93"
              bgColor="#8bc741"
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <ControlRoom />
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <Activitystatus />
          </Grid>

          <Grid item xs={12} md={6} lg={4}></Grid>

          <Grid item xs={12} md={6} lg={8}>
            <p style={{ fontWeight: '500', fontSize: '18px' }}>{t('Recent Activity')}</p>
            <div style={{ position: 'relative' }}>
              <Carousel
                itemsToShow={2}
                itemPosition={consts.START}
                ref={ref => (this.carousel = ref)}
                renderPagination={({ pages, activePage, onClick }) => {
                  return (
                    <div className="recent-activity-pagination">
                      {pages.map((page, index) => {
                        const isActivePage = activePage === page;
                        return (
                          <span
                            onClick={() => onClick(page)}
                            style={isActivePage ? { backgroundColor: '#007eff' } : {}}
                          >
                            {index + 1}
                          </span>
                        );
                      })}
                    </div>
                  );
                }}
              >
                <div style={{ padding: '10px 15px 10px 0px' }}>
                  <RecentActivity />
                </div>
                <div style={{ padding: '10px 15px 10px 0px' }}>
                  <RecentActivity />
                </div>
                <div style={{ padding: '10px 15px 10px 0px' }}>
                  <RecentActivity />
                </div>
                <div style={{ padding: '10px 15px 10px 0px' }}>
                  <RecentActivity />
                </div>
              </Carousel>
              <Button
                variant="outlined"
                color="primary"
                style={{ float: 'right', borderRadius: '25px' }}
                onClick={() => this.carousel.slideNext()}
              >
                {t('View more')}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
            <p style={{ fontWeight: '500', fontSize: '18px' }}>{t('New Volunteers')}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Volunteers />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Volunteers />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Volunteers />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Volunteers />
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withTranslation()(DashboardApp);
