import React, { PureComponent } from 'react';
import { WellnessCenterType } from '../entityconfig';
import BaseView from './BaseView';

class WellnessCenterTypeView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(WellnessCenterType);
  }
}

export default WellnessCenterTypeView;
