import React, { Component } from 'react';
import { Actions } from 'core/redux-helper';
import { connect } from 'react-redux';
import { Pagination, Row, Col, Select, Modal, Checkbox, Button } from 'antd';
import CustomSelectLarge from 'components/CustomSelect/Select';
import { Videos } from '../../entityconfig';
import SimpleForm from '../../Screens/simple-form';
import API from 'store/requests';
import Axios from 'axios';

class VideoEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      count: 0,
      batchName: null,
      selectedRow: {},
      visible: false,
      activeRecordId: '',
      delvideos: {},
    };
    this.props.dispatch(
      Actions.requestForAPIVideoBatch({
        action: 'RequestForAPI',
        identifier: 'VideoBatch',
        StoredProcedureName: 'LOAD_DISTINCT_VIDEO_BATCHES',
      }),
    );
    this.getVideos(1);
  }

  deleteBatch = () => {
    this.sendBAtchReq('DeleteBatch', 'API_DELETE_VIDEOS_BATCH');
  };

  showBatch = () => {
    this.sendBAtchReq('ShowBatch', 'API_SHOW_VIDEOS_BATCH');
  };

  hideBatch = () => {
    this.sendBAtchReq('HideBatch', 'API_HIDE_VIDEOS_BATCH');
  };

  sendBAtchReq = (identifier, StoredProcedureName) => {
    if (this.state.batchName == null || this.state.batchName == '') {
      alert('select batch');
      return;
    }
    this.props.dispatch(
      Actions.operationRequestDeleteBatch({
        action: 'OperationRequest',
        identifier,
        StoredProcedureName,
        Params1: this.state.batchName,
      }),
    );
    this.getVideos(this.state.pageNo, [
      { filterTerm: this.state.batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
    ]);
  };

  getVideos = (pageNo, filter) => {
    this.props.dispatch(
      Actions.listVideos({
        action: 'list',
        identifier: 'Videos',
        filterInfo: this.state.batchName ? filter : null,
        pageNo: pageNo - 1,
        pageSize: 20,
      }),
    );
  };

  onChange = page => {
    this.getVideos(page, [
      { filterTerm: this.state.batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
    ]);
    this.setState({
      pageNo: page,
    });
  };

  componentWillReceiveProps({ count }) {
    if (count && this.props.count !== count) {
      this.setState({ count });
    }
  }

  handleChange = (batchName, opt) => {
    this.setState({ batchName }, () =>
      this.getVideos(this.state.pageNo, [
        { filterTerm: batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
      ]),
    );
  };

  refresh = () => {
    this.getVideos(this.state.pageNo, [
      { filterTerm: this.state.batchName, filterBy: 'BatchName', filterType: 'EQUALS' },
    ]);

    this.props.dispatch(
      Actions.requestForAPIVideoBatch({
        action: 'RequestForAPI',
        identifier: 'VideoBatch',
        StoredProcedureName: 'LOAD_DISTINCT_VIDEO_BATCHES',
      }),
    );
  };

  toggle = (tog, selectedRow) => {
    this.setState({
      visible: tog,
      selectedRow,
      activeRecordId: selectedRow ? selectedRow[Videos.idColumn] : '',
    });
  };

  deleteAll = () => {
    let { delvideos } = this.state;
    let vid = Object.values(delvideos).filter(item => item);
    let me = this;
    Modal.confirm({
      title: 'Are you sure u want to delete ' + vid.length + ' videos',
      content: (
        <Row gutter={16}>
          {vid &&
            vid.map((item, index) => (
              <Col className="gutter-row" span={6}>
                <video
                  poster={item.ThumbnailUrl}
                  controls
                  style={{ height: '200px', width: 'calc(100%)' }}
                >
                  <source src={item.VideoURL} type="video/mp4" />
                </video>
              </Col>
            ))}
        </Row>
      ),
      onOk: async () => {
        for (var item in vid) {
          me.confirmDelete(vid[item], vid.length - 1 == item);
        }
        this.setState({ delvideos: {} });
        //alert('competed');
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
      width: 1000,
    });
  };

  confirmDelete = (selectedRow, isRefresh) => {
    var idColumn = Videos.idColumn;
    var activeRecordId = selectedRow[idColumn];

    var payload = null;

    payload = {
      action: 'delete',
      VideosId: activeRecordId,
      identifier: 'Videos',
    };

    API.triggerPost('videos', payload)
      .then(response => {
        var data = response.data;
        if (data.success) {
          if (isRefresh) this.onChange(this.state.pageNo);
          //this.setState({ isRefresh: true }, () => (this.state.isRefresh = false));
        } else {
          alert('Failed');
        }
      })
      .catch(error => {
        alert(JSON.stringify(error));
      });
  };

  getVideoImage(path, secs, callback) {
    var me = this,
      video = document.createElement('video');
    video.onloadedmetadata = function() {
      if ('function' === typeof secs) {
        secs = secs(this.duration);
      }
      this.currentTime = 0;
    };
    video.onseeked = function(e) {
      var canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      callback.call(me, img, this.currentTime, e);
    };
    video.onerror = function(e) {
      callback.call(me, undefined, undefined, e);
    };
    video.src = path;
  }

  VideoURL = (newValue, value, onChange, { formPanel }) => {
    this.getVideoImage(window.URL.createObjectURL(newValue), 10, img => {
      formPanel.setState({ ThumbnailUrl: img.src });
    });
    onChange(newValue, 'file');
  };

  ThumbnailUrl = (newValue, value, onChange, { formPanel }) => {
    var img = new Image();
    img.onload = function() {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, 100, 100);
      formPanel.setState({ ThumbnailUrl: canvas.toDataURL() });
    };
    img.src = window.URL.createObjectURL(newValue);
  };

  getBase64Image = img => {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    canvas
      .getContext('2d')
      .drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

    canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);

    var dataURL = canvas.toDataURL();
    debugger;

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  };

  removeDulicates = () => {
    let ThumbnailBase64 = {};
    for (let item of this.props.videos) {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        // alert('loaded');
        var canvas = document.createElement('canvas');
        canvas
          .getContext('2d')
          .drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
        // However the execution of any of the following methods will make your script fail
        // if your image doesn't has the right permissions
        canvas.getContext('2d').getImageData(0, 0, 100, 100);
        var img = canvas.toDataURL(0, 0, image.width, image.height);
        ThumbnailBase64[item.VideosId] = img;
      };
      image.src = item.ThumbnailUrl;
    }
    // alert(JSON.stringify(ThumbnailUrl));
    // for (let item of ThumbnailUrl) {
    //   let imagea = new Image();
    //   imagea.src = item;
    //   imagea.crossOrigin = 'anonymous';
    //   let imagea64 = this.getBase64Image(imagea);
    //   for (let item2 of ThumbnailUrl) {
    //     let imageb = new Image();
    //     imageb.src = item2;
    //     imageb.crossOrigin = 'anonymous';
    //     let imageb64 = this.getBase64Image(imageb);
    //     debugger;
    //     if (imagea64 === imageb64) {
    //       alert('same');
    //       break;
    //     }
    //   }
    //   break;
    // }
  };

  render() {
    const { data, videos, dataS } = this.props;
    const { count, selectedRow, visible, activeRecordId, batchName, delvideos } = this.state;
    const { Option } = Select;

    const columns = [
      { title: 'BatchName', dataIndex: 'BatchName' },
      { title: 'UserId', type: 'combo', dataIndex: 'UserId', comboType: 'Users' },
      { title: 'VideosId', dataIndex: 'VideosId' },
      { title: 'PlaceId', type: 'combo', dataIndex: 'PlaceId', comboType: 'Place' },
      { title: 'IsLocation', type: 'boolean', dataIndex: 'IsLocation', isRequired: true },
      { title: 'CityId', type: 'combo', dataIndex: 'CityId', comboType: 'City' },
      {
        title: 'ViewCount',
        type: 'int',
        dataIndex: 'ViewCount',
        isRequired: true,
        allowNegatives: true,
      },
      { title: 'Hashtag', type: 'string', dataIndex: 'Hashtag' },
      { title: 'Hashtag1', type: 'string', dataIndex: 'Hashtag1' },
      { title: 'Hashtag2', type: 'string', dataIndex: 'Hashtag2' },
      { title: 'Hashtag3', type: 'string', dataIndex: 'Hashtag3' },
      { title: 'Hashtag4', type: 'string', dataIndex: 'Hashtag4' },
      { title: 'Hashtag5', type: 'string', dataIndex: 'Hashtag5' },
      { title: 'Emoji', type: 'string', dataIndex: 'Emoji' },
      { title: 'Latitude', type: 'float', dataIndex: 'Latitude' },
      { title: 'Longitude', type: 'float', dataIndex: 'Longitude' },
      { title: 'MigratedDate', type: 'datetime', dataIndex: 'MigratedDate' },
      { title: 'Date', type: 'datetime', dataIndex: 'Date' },
      {
        colSpan: 12,
        dataIndex: 'VideoDescription',
        isRequired: true,
        title: 'VideoDescription',
        type: 'textarea',
        rowSpan: 12,
      },
      {
        title: 'VideoURL',
        type: 'videoUpload',
        dataIndex: 'VideoURL',
        colSpan: 12,
        rowSpan: 13,
        onChange: this.VideoURL,
      },
      {
        title: 'ThumbnailUrl',
        type: 'imageUpload',
        dataIndex: 'ThumbnailUrl',
        colSpan: 12,
        height: 250,
        width: 250,
        rowSpan: 14,
        onChange: this.ThumbnailUrl,
      },
    ];

    return (
      <div>
        <div>
          <CustomSelectLarge
            onChange={this.handleChange}
            style={{ width: '20%', margin: '10px' }}
            value={batchName}
            className="edit-select"
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().startsWith(input.toLowerCase())
            }
            getPopupContainer={() =>
              document.getElementsByClassName('edit-select')[0].parentElement.parentElement
                .parentElement.parentElement
            }
            allowClear
            dropdownMatchSelectWidth={true}
            onInputKeyDown={event => {
              // TO DO : work on the keyDown chage value
              // .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active target this class to get value
            }}
          >
            {dataS.map(item => {
              return (
                <Option value={item} title={item}>
                  {item}
                </Option>
              );
            })}
          </CustomSelectLarge>
          <Button onClick={this.refresh} style={{ margin: '10px' }}>
            Refresh
          </Button>
          <Button onClick={this.deleteAll} style={{ margin: '10px' }}>
            Delete Selected
          </Button>
          {batchName && batchName !== '' ? (
            <>
              <Button
                onClick={() =>
                  this.toggle(true, { VideosId: 'NEW_RECORD', BatchName: this.state.batchName })
                }
              >
                Add a video to this batch
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => this.toggle(true, { VideosId: 'NEW_RECORD' })}>
                Add a video
              </Button>
            </>
          )}
          {batchName && batchName !== '' && videos[0] && (
            <>
              <Button onClick={this.deleteBatch} style={{ margin: '10px' }}>
                Delete Batch
              </Button>
              <Button style={{ margin: '10px' }} onClick={this.removeDulicates}>
                Remove Duplicates for this batch
              </Button>
              {videos[0].Hide ? (
                <Button onClick={this.showBatch} className="hidden-batch-buttons">
                  BATCH hidden
                </Button>
              ) : (
                <Button onClick={this.hideBatch} className="live-batch-buttons">
                  BATCH live
                </Button>
              )}
            </>
          )}
        </div>
        <br />
        <Row gutter={16}>
          {videos.map(item => (
            <Col className="gutter-row" span={6}>
              <button onClick={() => this.toggle(true, item)}>edit</button>
              <button onClick={() => this.confirmDelete(item)}>Delete</button>
              <div>
                <Checkbox
                  className="video-checkbox"
                  checked={delvideos[item.VideosId]}
                  onChange={eve => {
                    this.setState({
                      delvideos: {
                        ...delvideos,
                        [item.VideosId]: eve.target.checked ? item : false,
                      },
                    });
                  }}
                />
                <video
                  poster={item.ThumbnailUrl}
                  controls
                  style={{ height: '200px', width: 'calc(100%)' }}
                >
                  <source src={item.VideoURL} type="video/mp4" />
                </video>
                <div style={{ height: 40 }}>
                  {item.Latitude},{item.Longitude}, {item.Date && Date(item.Date)}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <br />
        {count !== 0 ? (
          <Pagination
            defaultPageSize={20}
            current={this.state.pageNo}
            onChange={this.onChange}
            total={count}
          />
        ) : (
          ''
        )}
        <Modal
          visible={visible}
          footer={null}
          onCancel={() => {
            return this.toggle(false);
          }}
          width={1024}
          destroyOnClose
        >
          <SimpleForm
            columns={columns}
            selectedRow={selectedRow}
            identifier={'Videos'}
            apiIdentifier={'videos'}
            toggle={this.toggle}
            visible={visible}
            config={Videos}
            activeRecordId={activeRecordId}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({
  requestforapi_videobatch,
  list_videos,
  operationrequest_showbatch,
  operationrequest_hidebatch,
  operationrequest_deletebatch,
}) => {
  let data = [],
    dataS = [],
    videos = [],
    count = null;
  if (requestforapi_videobatch.data && requestforapi_videobatch.data.data) {
    data = requestforapi_videobatch.data.data;
    data.forEach(item => dataS.push(item.BatchName));
    dataS.sort();
  }
  if (list_videos.data && list_videos.data.data) {
    videos = list_videos.data.data;
    count = list_videos.data.total;
  }
  return { data, videos, count, dataS };
};

export default connect(mapStateToProps)(VideoEditor);
