import React, { PureComponent, useState, useEffect } from 'react';
// import { ScouterEvent } from '../entityconfig';
import BaseView from '../BaseView';
//screen important
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux';
import { Actions } from '../../core/redux-helper';
import Modal from '@material-ui/core/Modal';
import { useDispatch } from 'react-redux';
import { Input, DatePicker } from 'antd';
import SimpleForm from '../simple-form';
import moment from 'moment';
import DateFormat, { Util } from '../../util/date';
import CloseIcon from '@material-ui/icons/Close';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  card: {
    margin: 10,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 'auto',
    height: 'auto',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  paper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    // overflow: 'scroll',
  },
}));

let ImgMediaCard = ({ mobile, operationSaveSucess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const a = {};
  // displayData.map(item => (a[item.eventId] = false));
  const [selectedmobile, setSelectedmobile] = useState(a);
  const [selectedImage, setSelectedImage] = useState({});
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const modalClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    debugger
    if (operationSaveSucess) {
      dispatch(
        Actions.requestForJsonMobile({
          apiIdentifier: 'Mobile',
          identifier: 'mobile',
          action: 'RequestForJson',
          StoredProcedureName: 'UI_Scrapper_GetEvents_ForVerification',
        }),
      );
      handleClose();
    }
  }, [operationSaveSucess]);

  return (
    <>
      <div
        style={{
          position: 'sticky',
          zIndex: '99',
          top: '51px',
          // margin: '15px 0px',
          background: '#f8f8f8',
          padding: ' 15px 0px',
        }}
      >
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            onClick={() => {
              dispatch(
                Actions.operationRequestMobile({
                  apiIdentifier: 'Mobile',
                  identifier: 'mobile',
                  action: 'OperationRequest',
                  StoredProcedureName: 'UI_Scrapper_GetEvents_ForVerification_Delete',
                  Params1: Object.keys(selectedmobile)
                    .filter(item => selectedmobile[item])
                    .toString(),
                }),
              );
              alert('Are You Sure Delete');
            }}
          >
            Delete
          </Button>
        </ButtonGroup>
      </div>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        style={{
          zIndex: '99999',
        }}
      >
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleClose();
              }}
            >
              {' '}
            </CloseIcon>
          </div>
          <div style={{ display: 'flex', overflow: 'auto' }}>
            <div style={{ marginRight: 10, marginTop: '20px', borderRadius: '20px' }}>
              <Button
                onClick={() => {
                  dispatch(
                    Actions.operationRequestMobile({
                      apiIdentifier: 'Mobile',
                      identifier: 'mobile',
                      action: 'OperationRequest',
                      StoredProcedureName: 'UI_Scrapper_GetEvents_ForVerification_Delete',
                      Params1: selectedImage.shortcode,
                    }),
                  );
                 
                }}
              >
                Delete
              </Button>
              <img
                alt={selectedImage.alt}
                src={selectedImage.display_url}
                title={selectedImage.PlaceName}
                height="500"
                width="500"
                style={{ borderRadius: '1%' }}
              />
            </div>

           <div style ={{maxHeight:'500px'}}>
           <SimpleForm
              columns={[
                {
                  dataIndex: 'PlaceName',
                  title: 'Place Name',
                  type: 'input',
                  isRequired: true,
                  colSpan: '12',
                },
                { dataIndex: 'Lat', title: 'Lat', type: 'input', isRequired: true },
                { dataIndex: 'Long', title: 'Long', type: 'input', isRequired: true },
                { dataIndex: 'Slug', title: 'Slug', type: 'input' },
                { dataIndex: 'Blurb', title: 'Blurb', type: 'input' },
                { dataIndex: 'Website', title: 'Website', type: 'input' },
                { dataIndex: 'Phone', title: 'Phone', type: 'input' },
                { dataIndex: 'zipCode', title: 'Zip Code', type: 'input', isRequired: true },
                { dataIndex: 'cityName', title: 'City Name', type: 'input', isRequired: true },
                {
                  dataIndex: 'countryCode',
                  title: 'Country Code',
                  type: 'input',
                  isRequired: true,
                },
                {
                  dataIndex: 'EventDate',
                  title: 'Event Date',
                  type: 'datetime',
                  isRequired: true,
                  disablePastDate: moment().subtract(1, 'day'),
                },
                {
                  dataIndex: 'text',
                  title: 'Text',
                  type: 'textarea',
                  isRequired: true,
                  colSpan: '24',
                  rowSpan: '7',
                },
              ]}
              selectedRow={selectedImage}
              // identifier={identifier}
              // apiIdentifier={apiIdentifier}
              // toggle={toggle}
              // activeRecordId={activeRecordId}
              // visible={visible}
              // mode={mode}
              config={{
                customButtonsInForm: {
                  beforeSaveButton: [
                    {
                      buttonText: 'Save',
                      style: { marginRight: '10px', marginTop: '50px', },
                      onClick: ({ formPanel }) => {
                        formPanel.props.form.validateFields(err => {
                          if (!err) {
                            var values = formPanel.state;
                            var columns = formPanel.props.columns;
                            var json = {};
                            for (var col of columns) {
                              var fieldId = col.dataIndex;
                              if (col.type == 'date' && values[fieldId]) {
                                json[col.dataIndex] = values[fieldId].format(Util.dateParamFormat);
                              } else {
                                if (
                                  typeof values[fieldId] !== 'undefined' &&
                                  (values[fieldId] || values[fieldId] == 0)
                                ) {
                                  json[col.dataIndex] = values[fieldId];
                                } else {
                                  json[col.dataIndex] = '';
                                }
                              }
                            }
                            dispatch(
                              Actions.operationRequestMobile({
                                apiIdentifier: 'Mobile',
                                identifier: 'mobile',
                                action: 'OperationRequest',
                                StoredProcedureName: 'UI_Scrapper_GetEvents_ForVerification_Insert',
                                Params1: JSON.stringify({
                                  shortcode: selectedImage.shortcode,
                                  json,
                                }),
                              }),
                            );
                          }
                        });
                      },
                    },
                    {
                      buttonText: 'Cancel',
                      style: { marginRight: '10px', marginTop: '50px' },
                      onClick: ({ formPanel }) => {
                        handleClose();
                      },
                    },
                  ],
                },
              }}
              hideSave
              hideCancel
            />
           </div>
           
          </div>
          <div
            style={{
              display: 'flex',
              height: '100px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* <Button variant="outlined" color="primary">
              Delete
            </Button>
            <Button variant="outlined" color="primary">
              Save
            </Button> */}
          </div>
        </div>
      </Modal>
      <GridList cellHeight={300} spacing={5} className={classes.gridList} cols={4}>
        {mobile &&
          mobile.map(item => {
            return (
              <GridListTile
                key={item.display_url}
                top={-47}
                cols={1}
                rows={1}
                onClick={() => {
                  setSelectedImage(item);
                  handleOpen();
                }}
              >
                <img alt={item.alt} src={item.display_url} title={item.PlaceName} />

                <GridListTileBar
                  // title={item.PlaceName}
                  // subtitle={<span> {item.PlaceName}</span>}
                  style={{ background: 'transparent' }}
                  actionIcon={
                    <IconButton
                      // aria-label={`info about ${item.PlaceName}`}
                      className={classes.icon}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
                <GridListTileBar
                  titlePosition="top"
                  // title={item.PlaceName}
                  // subtitle={<span> {item.PlaceName}</span>}
                  actionIcon={
                    <Checkbox
                      onChange={event => {
                        setSelectedmobile({
                          ...selectedmobile,
                          [item.shortcode]: event.target.checked,
                        });
                      }}
                      onClick={event => {
                        event.stopPropagation();
                      }}
                      value="primary"
                    />
                  }
                  style={{ background: 'transparent' }}
                />
              </GridListTile>
            );
          })}
      </GridList>
    </>
  );
};
const mapStateToProps2 = ({ operationrequest_mobile }) => {
  let operationSaveSucess = false;
  if (operationrequest_mobile.data && operationrequest_mobile.data.data) {
    operationSaveSucess = operationrequest_mobile.data.success;
  }
  return {
    operationSaveSucess,
  };
};
ImgMediaCard = connect(mapStateToProps2)(ImgMediaCard);

class ScouterMobileView extends BaseView {
  constructor(props) {
    super({ props });
    // this.config = this.constructConfig(ScouterEvent);
  }
  componentDidMount() {
    this.props.dispatch(
      Actions.requestForJsonMobile({
        apiIdentifier: 'Mobile',
        identifier: 'mobile',
        action: 'RequestForJson',
        StoredProcedureName: 'UI_Scrapper_GetEvents_ForVerification',
      }),
    );
  }
  render() {
    const { mobile } = this.props;
    return <ImgMediaCard mobile={mobile} />;
  }
}

const mapStateToProps = ({ requestforjson_mobile }) => {
  let mobile;
  if (requestforjson_mobile && requestforjson_mobile.data) {
    mobile = requestforjson_mobile.data.data;
  }
  return {
    mobile,
  };
};

export default connect(mapStateToProps)(ScouterMobileView);
