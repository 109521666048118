import React from 'react';
import styled from 'styled-components';
import { Tree, TreeNode } from 'react-organizational-chart';
import treeData from './treeData.json'; 

const StyledNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid green; 
`;

const renderTreeNode = (nodeData) => (
  <TreeNode label={<StyledNode>{nodeData.label}</StyledNode>}>
    {nodeData.children?.map(renderTreeNode)}
  </TreeNode>
);

export default function Demo(){
  return(
<Tree
     lineColor='red'
     lineWidth='2px'
     lineBorderRadius='10px'
 label={<StyledNode style={{marginTop:'10px'}}>{treeData.label}</StyledNode>}>
  {treeData.children.map(renderTreeNode)}

</Tree>
  )
  
}

