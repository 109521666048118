import React from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import {
  Card,
  CardHeader,
  Box,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
//
import { BaseOptionChart } from './charts';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: 'Risk',
    type: 'line',
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
  },
  {
    name: 'Controls',
    type: 'line',
    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  },
  {
    name: 'Rejected',
    type: 'line',
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
  },
];

class AppWebsiteVisits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      risk: true,
      control: true,
      rejected: false,
      age: 'month',
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  handleSelect = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const chartOptions = {
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      },
      stroke: {
        curve: 'smooth',
      },
    };
    const { risk, control, rejected, age } = this.state;
    console.log(age);
    return (
      <Card style={{ borderRadius: '25px' }}>
        <h4 style={{ padding: '10px', fontWeight: '500' }}>
          Activity Status
          <span style={{ float: 'right' }}>
            <FormControl className="activity-status">
              {/* <InputLabel id="demo-customized-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                className="activity-select"
                value={age}
                name="age"
                onChange={this.handleSelect}
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="week">This Week</MenuItem>
                <MenuItem value="month">This Month</MenuItem>
              </Select>
            </FormControl>
          </span>
          <span style={{ float: 'right' }}>
            <Typography
              style={{ marginTop: 10, fontSize: '15px' }}
              variant="caption"
              display="inline-block"
              gutterBottom
            >
              Rejected
            </Typography>
            <Switch
              checked={rejected}
              onChange={this.handleChange}
              color="primary"
              name="rejected"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </span>
          <span style={{ float: 'right' }}>
            <Typography
              style={{ marginTop: 10, fontSize: '15px' }}
              variant="caption"
              display="inline-block"
              gutterBottom
            >
              Controls
            </Typography>
            <Switch
              checked={control}
              onChange={this.handleChange}
              color="primary"
              name="control"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </span>
          <span style={{ float: 'right' }}>
            <Typography
              style={{ marginTop: 10, fontSize: '15px' }}
              variant="caption"
              display="inline-block"
              gutterBottom
            >
              Risks
            </Typography>
            <Switch
              checked={risk}
              onChange={this.handleChange}
              color="primary"
              name="risk"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </span>
        </h4>
        {/* <CardHeader title="Activity Status" subheader="(+43%) than last year" /> */}
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
        </Box>
      </Card>
    );
  }
}

export default AppWebsiteVisits;
