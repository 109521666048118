import React, { Component } from 'react';
import Button from 'components/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import FilterIcon from '@material-ui/icons/Filter';
import SearchIcon from '@material-ui/icons/Search';
import { Select } from 'antd';
import Card from '@material-ui/core/Card';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

export class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: 'Hyderabad',
    };
  }

  handleSelect = value => {
    this.setState({ location: value });
  };

  render() {
    const { t } = this.props;
    return (
      <Card className="searchBarContainer">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={2}>
            <Select
              defaultValue="Hyderabad"
              value={this.state.location}
              className="selectLocation"
              onChange={event => {
                this.handleSelect(event);
              }}
            >
              <Option value="Hyderabad">{t('Hyderabad')}</Option>
              <Option value="Delhi">{t('Delhi')}</Option>
              <Option value="Mumbai">{t('Mumbai')}</Option>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <input
              className="searchBarInput"
              type="text"
              placeholder={t('Search by volunteer, type or any activities') + '...'}
            />
          </Grid>
          <Grid item container direction="row" justifyContent="flex-end" xs={4}>
            <Button
              variant="contained"
              size="large"
              className="filterButton"
              startIcon={<FilterIcon fontSize="small" />}
            >
              {t('Filter')}
            </Button>
            <Button
              variant="contained"
              className="findButton"
              size="large"
              startIcon={<SearchIcon fontSize="small" />}
            >
              {t('Find')}
            </Button>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default withTranslation()(SearchBar);
