import React from 'react';
//import Home from './Screens/home';
import Login from './Screens/login';
import DashboardIcon from '@material-ui/icons/Dashboard';
//import routes from './menu';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CategoryIcon from '@material-ui/icons/Category';
import StoreIcon from '@material-ui/icons/Store';
import StorageIcon from '@material-ui/icons/Storage';
import BusinessIcon from '@material-ui/icons/Business';
import UserIcon from '@material-ui/icons/Person';
import ActivitiesIcon from '@material-ui/icons/PermMedia';
import Survey from './Screens/Survey';
import SurveyQuestion from './Screens/SurveyQuestion';
import SurveyDetail from './Screens/SurveyDetail';
import ComplaintsIcon from '@material-ui/icons/Email';
import StatisticsIcon from '@material-ui/icons/PieChart';
import TodosIcon from '@material-ui/icons/Event';
import ResourcesIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import Activity from './Screens/Activity';
import ActivityType from './Screens/ActivityType';
import AttachmentType from './Screens/AttachmentType';
import City from './Screens/City';
import Product from './Screens/Product';
import Demo from 'Screens/Demo';
//import InsuranceProduct from './Screens/InsuranceProduct';
//import Policy from './Screens/Policy';
//import LookUpType from './Screens/LookUpType';
//import LookUp from './Screens/LookUp';
//import Party from 'Screens/Party';
//import Person from 'Screens/Person';
//import Transaction from 'Screens/Transaction';
//import Address from 'Screens/Address';
//import Billing from 'Screens/Billing';
//import PolicyCoverage from './Screens/PolicyCoverage';
import SecurityModuleView from 'Screens/SecurityModule';
import Comment from './Screens/Comment';
import ControlRoomRequest from './Screens/ControlRoomRequest';
import ControlRoomRequestReply from './Screens/ControlRoomRequestReply';
import Logs from './Screens/Logs';
import News from './Screens/News';
import ToDo from './Screens/ToDo';
import User from './Screens/User';
import UserRole from './Screens/UserRole';
import Dashboard from './Screens/Dashboard';
import WellnessCenter from './Screens/WellnessCenter';
import WellnessCenterType from './Screens/WellnessCenterType';
import Test1 from './Screens/Test1';
import Test2 from './Screens/Test2';
import Test3 from './Screens/Test3';
import Chat from 'Screens/Chat';
import MultiQueryResult from './Screens/MultiQueryResult';
import Application from './Screens/Application';
import AppConfig from './Screens/AppConfig';
import DynamicBaseView from './Screens/core/DynamicBaseView';

// Import Scouter Customized Pages
import ScouterEvent from './Screens/Scouter/ScouterEvent';
import DropDown from 'Screens/Scouter/DropDown';
import EventsUploader from 'Screens/Scouter/EventsUploader';
import CsvUsersCreate from 'Screens/Scouter/CsvUsersCreate';
import CommentEditer from 'Screens/Scouter/CommentEditer';
import CsvPlaceexrac from 'Screens/Scouter/CsvPlaceexrac';
import ScouterMobile from 'Screens/Scouter/ScouterMobile';
import ScouterMobileView from 'Screens/Scouter/ScouterMobile';
import VideoEditor from 'Screens/Scouter/VideoEditor';
import VideoLinkUploader from 'Screens/Scouter/VideoLinkUploader';

import { AutoText } from './Screens/confess';

// Import No Fever Customized Pages

const icons = {
  Visiblity: VisibilityIcon,
  TrendingUp: TrendingUpIcon,
  Category: CategoryIcon,
  Store: StoreIcon,
  Storage: StorageIcon,
  Business: BusinessIcon,
  User: UserIcon,
  Activities: ActivitiesIcon,
  Complaints: ComplaintsIcon,
  Statistics: StatisticsIcon,
  Todos: TodosIcon,
  Resources: ResourcesIcon,
  Settings: SettingsIcon,
  Home: HomeIcon,
};

const Components = {
  Dashboard,
  Activity,
  ActivityType,
  AttachmentType,
  City,
  Demo,
  Product,
  Survey,
  SurveyQuestion,
  SurveyDetail,
  SecurityModuleView,
  Comment,
  ControlRoomRequest,
  ControlRoomRequestReply,
  Logs,
  News,
  ToDo,
  User,
  UserRole,
  WellnessCenter,
  WellnessCenterType,
  Test1,
  Test2,
  Test3,
  Chat,
  MultiQueryResult,
  Application,
  AppConfig,
  ScouterEvent,
  DropDown,
  EventsUploader,
  CsvUsersCreate,
  CommentEditer,
  CsvPlaceexrac,
  ScouterMobile,
  ScouterMobileView,
  VideoEditor,
  VideoLinkUploader,
  AutoText,
};

let masterConfig = {};
if (localStorage.entityMapping) {
  masterConfig = JSON.parse(localStorage.entityMapping);
}

const DynamicComponent = tableName => {
  return <DynamicBaseView {...tableName} />;
};

const menu = JSON.parse(localStorage.getItem('menu'));
let Dashboardroutes = (menu ? menu : []).map((item, index) => {
  if (item.url)
    return {
      path: '/' + item.url,
      name: item.cardText,
      cardText: item.cardText,
      layout: 'dashboard',
      headerSubText: item.subText,
      component: Components[item.url] || (() => <div></div>),
      icon: icons[item.icon],
      /*item.icon
        ? props => (
            <img
              src={item.icon}
              {...props}
              style={{ width: '13px', height: '13px', marginTop: '8px' }}
            />
          )
        : undefined,*/
      // collapse: item.children && true,
      state: item.cardText + 'Collapse',
      hideHeader: item.hideHeader,
      views:
        item.children &&
        item.children.map((itemChi, index) => {
          let header = itemChi.cardText;
          if (header.indexOf('_') >= 0) {
            var array = header
              .split('_')
              .map(item => item.charAt(0).toUpperCase() + item.substring(1, item.length));
            header = array.join(' ');
          } else {
            header = header.charAt(0).toUpperCase() + header.substring(1, header.length);
          }
          return {
            hideCard: itemChi.hideCard,
            parentPath: '/' + item.url,
            path: '/' + itemChi.url,
            headerSubText: itemChi.displayText,
            cardText: itemChi.cardText,
            name: header,
            component: masterConfig[itemChi.tableName]
              ? () => <DynamicComponent tableName={itemChi.tableName} />
              : Components[itemChi.url] || (() => <div></div>),
            layout: 'dashboard',
            icon: DashboardIcon, //itemChi.icon ? (props => <img src={itemChi.icon} style={{ width: '13px', height: '13px', marginTop: '8px' }} {...props} />) : undefined,
            collapse: itemChi.children && true,
            hideHeader: itemChi.hideHeader,
            views:
              itemChi.children &&
              itemChi.children.map((itemChi1, index) => {
                return {
                  parentPath: itemChi.url.replace('Cube.', '/').replace('.ShowList()', ''),
                  path:
                    !itemChi1.children &&
                    itemChi1.url.replace('Cube.', '/').replace('.ShowList()', ''),
                  name: itemChi1.text,
                  component:
                    Components[itemChi1.url.replace('Cube.', '').replace('.ShowList()', '')],
                  layout: 'dashboard',
                  icon: DashboardIcon, //itemChi.icon ? (props => <img src={itemChi.icon} style={{ width: '13px', height: '13px', marginTop: '8px' }} {...props} />) : undefined,
                };
              }),
          };
        }),
    };
});

Dashboardroutes = Dashboardroutes.filter(item => item);

const LoginRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: 'login',
    icon: DashboardIcon,
  },
];

export default Dashboardroutes;
export { LoginRoutes };
