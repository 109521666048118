import React from 'react';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { country: '', region: '' };
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  render() {
    const { country, region } = this.state;
    return (
      <div>
        <h4>DropdownList Of Countries </h4>

        <div>
          <CountryDropdown
            value={country}
            style={{ width: '180px' }}
            onChange={val => this.selectCountry(val)}
          />
          <RegionDropdown
            country={country}
            value={region}
            style={{ width: '160px' }}
            onChange={val => this.selectRegion(val)}
          />
        </div>
      </div>
    );
  }
}

export default DropDown;
