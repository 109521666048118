import { Actions } from '../core/redux-helper';

export const AttachmentType = {
  key: 'AttachmentType',
  title: 'AttachmentType',
  idColumn: 'AttachmentTypeId',
  listAPI: Actions.listAttachmentType,
  getGridColumns: () => [
    {
      dataIndex: 'AttachmentTypeId',
      header: 'AttachmentTypeId',
    },
    {
      dataIndex: 'AttachmentTypeName',
      header: 'AttachmentTypeName',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'AttachmentTypeName',
      header: 'AttachmentTypeName',
      isRequired: true,
    },
  ],
  comboTypes: [],
  childs: () => [],
};
