import React from 'react';
import { Activity } from '../entityconfig';
import BaseView from './BaseView';

import Filters from './ActivityComponents/Filters';
import SearchBar from './ActivityComponents/SearchBar';
import Suggestions from './ActivityComponents/Suggestions';
import Grid from '@material-ui/core/Grid';

class ActivityView extends BaseView {
  constructor(props) {
    super(props);
    this.state = { customView: true };
    this.config = this.constructConfig(
      { ...Activity, formTitle: 'Indoor Insecticide Spraying' },
      this.state.customView,
    );
  }
  changeViewMode = viewMode => {
    this.setState({ customView: viewMode });
    this.config = this.constructConfig(Activity, viewMode);
  };

  gridHeader = ({ gridPanel }) => {
    this.gridPanel = gridPanel;
    const activityFilter = type => {
      var activityTypeCombos = gridPanel.props.combos['ActivityType'];
      var filterList = [];
      activityTypeCombos &&
        activityTypeCombos
          .filter(item => item.Type == type)
          .forEach(item => {
            filterList.push(item.LookupId);
          });
      var model = {
        ActivityTypeId: {
          type: 'listCustom',
          value: filterList.join(','),
        },
      };
      gridPanel.gridApi.setFilterModel(model);
    };
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs>
          <SearchBar />
        </Grid>
        <Grid item xs>
          <Suggestions
            add={() => this.gridPanel.buildButtons(gridPanel.props.isFetching)}
            filter={data => activityFilter(data)}
          />
        </Grid>
        <Grid item xs>
          <Filters customViewMode={this.changeViewMode} />
        </Grid>
      </Grid>
    );
  };
}

export default ActivityView;
