export const URL = window.location.origin;
//export const URL = 'https://cubeapp.azurewebsites.net'
export const EnableLogs = false;
export const isNewBackend = true;
export const isJWTAuthentication = true;
export const CLIENT_DESC = 'Tenant';
export const APIVersion = '/api/v1/';
export const isMultiTenant = true;
export const isSingleReset = false;
export const disableRippleEffect = false;
export const backButtonVisibility = false;
export const breadcrumbsVisibility = false;
export const titleVisibility = false;
export const openInNewTabButton = true;
export const isGuidSystem = true;
export const defaultActions = ['list', 'gridpreference'];
