import React, { Component } from 'react';
import { Card, List, ListItemAvatar, ListItem, Avatar, ListItemText } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import Progress from 'react-circle-progress-bar';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
class ControlRoom extends Component {
  render() {
    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <Card
          style={{
            padding: '20px',
            backgroundColor: this.props.bgColor,
            borderRadius: '25px',
            position: 'relative',
          }}
        >
          <div>
            <h4 style={{ fontWeight: '500', fontSize: '18px' }}>Recent Complaints</h4>
            <div>
              <img src="" />
            </div>
            <div>
              <div style={{ display: 'inline-block', marginLeft: '15px', textAlign: 'center' }}>
                <Progress
                  progress={66}
                  reduction={0}
                  ballStrokeWidth={0}
                  style={{ width: '75px' }}
                  strokeWidth={35}
                  gradient={[{ stop: 1, color: '#6bfa53' }]}
                />
                <span
                  style={{ color: 'grey', paddingTop: '5px', fontWeight: '500', fontSize: '12px' }}
                >
                  Closed
                </span>
              </div>

              <div style={{ display: 'inline-block', marginLeft: '15px', textAlign: 'center' }}>
                <Progress
                  progress={31}
                  reduction={0}
                  ballStrokeWidth={0}
                  style={{ width: '75px' }}
                  strokeWidth={35}
                  gradient={[{ stop: 1, color: '#a17df9' }]}
                />
                <span
                  style={{ color: 'grey', paddingTop: '5px', fontWeight: '500', fontSize: '12px' }}
                >
                  Active
                </span>
              </div>

              <div style={{ display: 'inline-block', marginLeft: '15px', textAlign: 'center' }}>
                <Progress
                  progress={7}
                  reduction={0}
                  ballStrokeWidth={0}
                  style={{ width: '75px' }}
                  strokeWidth={35}
                  gradient={[{ stop: 1, color: '#fe879d' }]}
                />
                <span
                  style={{ color: 'grey', paddingTop: '5px', fontWeight: '500', fontSize: '12px' }}
                >
                  Pending
                </span>
              </div>
            </div>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="supplies needed for spray thermocouple"
                  secondary="Jan 9, 2014"
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary="supplies needed for spray thermocouple"
                  secondary="Jan 9, 2014"
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="supplies needed for spray thermocouple"
                  secondary="Jan 9, 2014"
                />
              </ListItem>
            </List>
          </div>
        </Card>
        <div
          style={{
            position: 'absolute',
            bottom: '-20px',
            left: '45%',
            background: 'white',
            borderRadius: '50%',
            padding: '4px 8px',
            boxShadow: '3px -4px 47px 5px rgb(0 0 0 / 25%)',
          }}
        >
          <ArrowDownwardRoundedIcon style={{ transform: 'translateY(10%)' }} />
        </div>
      </div>
    );
  }
}

export default ControlRoom;
