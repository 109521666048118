import { Actions } from '../core/redux-helper';
import { defaultGridColumns } from '../core/utils';

export const Survey = {
  key: 'Survey',
  title: 'Survey',
  idColumn: 'SurveyId',
  listAPI: Actions.listSurvey,
  getGridColumns: () => [
    {
      dataIndex: 'SurveyId',
      header: 'Survey',
    },
    {
      dataIndex: 'SurveyTypeId',
      header: 'Survey Type',
      type: 'combo',
      comboType: 'SurveyTypeId',
    },
    {
      dataIndex: 'StartDate',
      header: 'Start Date',
      type: 'date',
    },
    {
      dataIndex: 'EndDate',
      header: 'End Date',
      type: 'date',
    },

    ...defaultGridColumns(),
  ],
  getFormColumns: () => [
    {
      dataIndex: 'SurveyTypeId',
      header: 'Survey Type',
      type: 'combo',
      comboType: 'SurveyTypeId',
    },
    {
      dataIndex: 'StartDate',
      header: 'Start Date',
      type: 'date',
    },
    {
      dataIndex: 'EndDate',
      header: 'End Date',
      type: 'date',
    },
  ],
  childs: () => [],
  comboTypes: [
    {
      type: 'SurveyTypeId',
      ValueField: 'Name',
      IDField: 'SurveyTypeId',
    },
  ],
};
