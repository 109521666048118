import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import {
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

const CustomLabel = withStyles({
  root: {
    color: 'black',
    fontSize: '20px',
    fontWeight: 700,
    paddingBottom: '10px',
  },
})(InputLabel);

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 20,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #e2e2e2',
    fontSize: 16,
    padding: '10px 12px',
    height:"20px",
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

function LoginInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helpText,
    val,
    type,
  } = props;

  return (
    <FormControl {...formControlProps}>
      <CustomLabel shrink htmlFor="bootstrap-input">
        {labelText}
      </CustomLabel>
      <CustomInput type={type} {...inputProps} />
      {helpText !== undefined ? (
        <FormHelperText id={id + '-text'}>{helpText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

LoginInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node,
};

export default LoginInput;
