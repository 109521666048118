import React, { Component } from 'react';
import { Card } from '@material-ui/core';
class ScoreCard extends Component {
  render() {
    return (
      <Card
        style={{
          padding: '20px',
          backgroundColor: this.props.bgColor,
          borderRadius: '15px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              flex: 0.3,
              alignSelf: 'center',
            }}
          >
            <div
              style={{ border: '1px solid white', borderRadius: '15px', padding: '10px 10px 4px' }}
            >
              {this.props.icon}
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ textAlign: 'right', color: 'white' }}>
              <div style={{ fontWeight: '400' }}>{this.props.title}</div>
              <div style={{ fontSize: '40px', marginTop: '15px', fontWeight: '500' }}>
                {this.props.score}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default ScoreCard;
