import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppBar, Toolbar, MenuItem, Select, FormControl, InputLabel, Paper } from '@mui/material';

class Test1 extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ borderRadius: '10px',boxShadow:"0px 1px 10px 1px rgba(0,0,0,0.15)"  }} >
              <Box display="flex" alignItems="center" p={2}>
                <TextField
                  placeholder="Search Client"
                  variant="outlined"
                  size="small"
                  sx={{ width: '850px', marginRight: '10px' }}
                  InputProps={{
                    style:{
                      borderRadius:"10px"
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon sx={{ color: 'action.active' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ width: '150px', borderRadius: '10px',  }}
                >
                  Search
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: '5px', borderRadius: '60px'  }}>
          <Grid item xs={12}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      marginBottom: '20px',
                      opacity: 0.8, 
                    }}
                  >
                    <strong>List of clients</strong>
                  </Typography>

                  <FormControl sx={{ width: '175px', marginTop: '-10px' }}>
                    <InputLabel id="demo-simple-select-label">Existing Clients</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      size="medium"
                      sx={{ borderRadius: '10px', height: '40px', marginTop: '10px' }}
                    >
                      <MenuItem value={10}>James</MenuItem>
                      <MenuItem value={20}>John</MenuItem>
                      <MenuItem value={30}>Peter</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ height: '150px', borderRadius: '15px', boxShadow:"0px 1px 10px 1px rgba(0,0,0,0.15)" }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '-10px',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <Typography variant="h6" component="div">
                          Insurance Products
                        </Typography>
                        <IconButton color="inherit" aria-label="menu">
                          <MoreVertIcon />
                        </IconButton>
                      </CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          marginTop: '15px',
                        }}
                      >
                        <Typography variant="h5">80</Typography>
                        <Typography variant="h5">76</Typography>
                        <Typography variant="h5">4</Typography>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Typography variant="body2">Policies</Typography>
                        <Typography variant="body2">Active</Typography>
                        <Typography variant="body2">Inactive</Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ height: '150px', borderRadius: '15px',boxShadow:"0px 1px 10px 1px rgba(0,0,0,0.15)"  }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '-10px',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <Typography variant="h6" component="div">
                          EV Bikes
                        </Typography>
                        <IconButton color="inherit" aria-label="menu">
                          <MoreVertIcon />
                        </IconButton>
                      </CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          marginTop: '15px',
                        }}
                      >
                        <Typography variant="h5">80</Typography>
                        <Typography variant="h5">76</Typography>
                        <Typography variant="h5">4</Typography>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Typography variant="body2">Policies</Typography>
                        <Typography variant="body2">Active</Typography>
                        <Typography variant="body2">Inactive</Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ height: '150px', borderRadius: '15px' ,boxShadow:"0px 1px 10px 1px rgba(0,0,0,0.15)"  }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '-10px',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <Typography variant="h6" component="div">
                          ShellPolicy llc
                        </Typography>
                        <IconButton color="inherit" aria-label="menu">
                          <MoreVertIcon />
                        </IconButton>
                      </CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          marginTop: '15px',
                        }}
                      >
                        <Typography variant="h5">80</Typography>
                        <Typography variant="h5">76</Typography>
                        <Typography variant="h5">4</Typography>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Typography variant="body2">Policies</Typography>
                        <Typography variant="body2">Active</Typography>
                        <Typography variant="body2">Inactive</Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ height: '150px', borderRadius: '15px' ,boxShadow:"0px 1px 10px 1px rgba(0,0,0,0.15)"  }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '-10px',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <Typography variant="h6" component="div">
                          ShellPolicy llc
                        </Typography>
                        <IconButton color="inherit" aria-label="menu">
                          <MoreVertIcon />
                        </IconButton>
                      </CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          marginTop: '15px',
                        }}
                      >
                        <Typography variant="h5">80</Typography>
                        <Typography variant="h5">76</Typography>
                        <Typography variant="h5">4</Typography>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Typography variant="body2">Policies</Typography>
                        <Typography variant="body2">Active</Typography>
                        <Typography variant="body2">Inactive</Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ height: '150px', borderRadius: '15px' ,boxShadow:"0px 1px 10px 1px rgba(0,0,0,0.15)" }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '-10px',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        
                        <Typography variant="h6" component="div">
                          ShellPolicy llc
                        </Typography>
                        <IconButton color="inherit" aria-label="menu">
                          <MoreVertIcon />
                        </IconButton>
                      </CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          marginTop: '15px',
                        }}
                      >
                        <Typography variant="h5">80</Typography>
                        <Typography variant="h5">76</Typography>
                        <Typography  variant="h5" >4</Typography>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Typography variant="body2">Policies</Typography>
                        <Typography variant="body2"  sx={{marginLeft:"30px"}}>Active</Typography>
                        <Typography variant="body2" sx={{marginLeft:"30px"}}>Inactive</Typography>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Test1;
