import React, { Component, PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { Avatar, Divider } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PhotoIcon from '@material-ui/icons/Photo';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import no_dengue from 'assets/img/no_dengue.png';

import Paper from '@material-ui/core/Paper';

class Header extends PureComponent {
  constructor(props) {
    super({ props });
    this.state = {
      viewMode: false,
      sortMode: 'newest',
      open: false,
    };
  }

  handleChange = event => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Typography gutterBottom className="modal-form-title">
            Need Assistance with Temophos
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <div className="flex-container">
            <Typography variant="caption" component="span">
              Hide
            </Typography>
            <Switch
              checked={this.state.checkedA}
              onChange={this.handleChange}
              name="checkedA"
              color="primary"
            />
          </div>
        </Grid>
        <Grid container xs={5} direction="row" justifyContent="flex-end" alignItems="center">
          <Button variant="contained" className="basic-button grey-button mr-15">
            Cancel
          </Button>
          <Button variant="contained" className="basic-button blue-button button-width">
            Export CSV
          </Button>
        </Grid>
      </Grid>
    );
  }
}

class Request extends PureComponent {
  render() {
    return (
      <Grid container spacing={3} style={{ marginTop: 25 }}>
        <Grid container spacing={2}>
          <Grid item>
            <div className="modal-form-image-container">
              <img
                className="modal-form-image"
                src="http://res.cloudinary.com/demo/image/upload/v1312461204/sample.jpg"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row" spacing={2}>
              <Avatar
                src="https://www.beehive.govt.nz/sites/default/files/styles/portrait_image/public/2020-11/j-ardern.jpg?itok=3SoPJ2Dj"
                alt="image"
                className="mr-15"
              />
              <Typography gutterBottom variant="subtitle1" className="text-transform-none">
                Rajesh Kumar
              </Typography>
            </Grid>
            <Grid item alignItems="center">
              <Typography variant="body2" color="textSecondary">
                09:30 July 14, 2021
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                It is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout. The point of using Lorem Ipsum is that
                it has a more-or-less normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

class Reply extends PureComponent {
  render() {
    return (
      <Paper className="reply-container">
        <div className="reply-box">
          <div className="id-container" style={{ padding: 10 }}>
            <Avatar src={no_dengue} alt="image" className="mr-15" />
            <Typography variant="subtitle2" className="text-transform-none opacity-6">
              The Control Room
            </Typography>
          </div>
          <TextareaAutosize
            maxRows={8}
            minRows={8}
            aria-label="minimum height"
            placeholder="Write here..."
            className="reply-content-container"
          />
          <div className="divider"></div>
          <div className="reply-content-actions">
            <Button
              variant="contained"
              color="primary"
              className="mr-15 text-transform-none blue-button"
            >
              Send
            </Button>
            <AttachFileIcon className="mr-15 opacity-6" />
            <PhotoIcon className="mr-15 opacity-6" />
          </div>
        </div>
      </Paper>
    );
  }
}

export class DialogForm extends Component {
  handleClose = () => {
    if (this.props.open === true) {
      this.props.onclose(false);
    }
  };
  render() {
    return (
      <>
        <Dialog
          open={this.props.open}
          maxWidth="lg"
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          className="modal-form"
        >
          <DialogContent>
            <Header />
            <div className="id-container">
              <Typography className="id-text" gutterBottom>
                Request #123456789
              </Typography>
              <Typography variant="caption" className="status-content">
                Pending
              </Typography>
            </div>
            <Request />
            <Divider style={{ marginTop: 35, marginBottom: 35 }} />
            <Reply />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default DialogForm;
