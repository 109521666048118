import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';

export class ActivityCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardViewData: [],
      noOfPages: Math.ceil(props.data.length / 9),
      page: 1,
      currentPage: 0,
      itemsPerPage: 9,
    };
  }

  componentDidMount() {
    this.setState({ cardViewData: this.props.data });
    this.onGridReady();
  }

  componentWillReceiveProps({ isRefresh, total, isFetching, data }) {
    if (isRefresh && isRefresh != this.props.isRefresh) {
      this.loadData();
    }
    if (data !== this.props.data) {
      this.setState({
        cardViewData: data,
        noOfPages: Math.ceil(data.length / 9),
      });
    }
  }

  loadData = () => {
    let requestOptions = {
      currentPage: this.state.page - 1,
      filter: JSON.parse(this.props.gridPreferences).filterInfo,
      limit: 50,
      sortInfo: JSON.parse(this.props.gridPreferences).sortInfo,
    };
    this.props.loadData(requestOptions);
  };

  onGridReady = () => {
    if (this.props.autoRefresh) {
      this.loadData();
    }
  };

  combos = (comboType, data) => {
    let value = this.props.combo[comboType].find(item => item.LookupId == data);
    return <span>{value && value.DisplayValue}</span>;
  };

  getTime = modifiedDate => {
    let time = moment(modifiedDate).fromNow();
    return <span>{time}</span>;
  };

  handlePageChange = (event, value) => {
    this.setState({ page: value });
  };

  render() {
    const { getCardView } = this.props;
    return (
      <>
        <Grid container spacing={3}>
          {this.state.cardViewData
            .slice(
              (this.state.page - 1) * this.state.itemsPerPage,
              this.state.page * this.state.itemsPerPage,
            )
            .map((data, index) => {
              return (
                <Grid item xs={4} key={data.ActivityId}>
                  <Card style={{ borderRadius: 15 }}>
                    <CardContent>
                      <div className="activity-cardview-container">
                        <Grid container spacing={3}>
                          <Grid item xs={9}>
                            <Typography
                              variant="caption"
                              gutterBottom
                              fontWeight="fontWeightLight"
                              className="opacity-6"
                              style={{ opacity: 0.7 }}
                            >
                              Volunteer
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              className="text-transform-none"
                            >
                              {data[getCardView.title]}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="caption"
                              className="activity-cardview-time"
                            >
                              {this.getTime(data[getCardView.time])}
                            </Typography>
                          </Grid>
                          <Grid xs={3} item className="activity-cardview-avatar">
                            <Avatar
                              variant="rounded"
                              src={data[getCardView.thumbImg]}
                              alt="image"
                            />
                          </Grid>
                        </Grid>
                        <div>
                          <Typography
                            variant="caption"
                            fontWeight="fontWeightLight"
                            style={{ opacity: 0.7 }}
                          >
                            {data[getCardView.description]}
                          </Typography>
                        </div>
                        <div className="activity-cardview-status-flex">
                          <Button
                            variant="contained"
                            className="blue-button text-transform-none br-15"
                            size="small"
                            onClick={() =>
                              this.props.selectRow(index + 9 * (this.state.page - 1), 'Update')
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            className="grey-button text-transform-none br-15"
                            variant="contained"
                            size="small"
                            onClick={() =>
                              this.props.selectRow(index + 9 * (this.state.page - 1), 'Delete')
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
        {this.state.cardViewData.length !== 0 ? (
          <div className="pagination-container">
            <Typography variant="caption">
              Showing 9 from {this.state.cardViewData.length} results
            </Typography>
            <Pagination
              className="pagination"
              count={this.state.noOfPages}
              page={this.state.page}
              onChange={this.handlePageChange}
              defaultPage={1}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default ActivityCardView;
