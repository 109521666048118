import React, { PureComponent } from 'react';
import { Comment } from '../entityconfig';
import BaseView from './BaseView';

class CommentView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(Comment);
  }
}

export default CommentView;
