import React, { PureComponent } from 'react';
import { AttachmentType } from '../entityconfig';
import BaseView from './BaseView';

class AttachmentTypeView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(AttachmentType);
  }
}

export default AttachmentTypeView;
