import { Actions } from '../core/redux-helper';

export const News = {
  key: 'News',
  title: 'News',
  idColumn: 'NewsId',
  listAPI: Actions.listNews,
  getGridColumns: () => [
    {
      dataIndex: 'NewsId',
      header: 'NewsId',
    },
    {
      dataIndex: 'ActivityTypeId',
      header: 'ActivityTypeId',
      type: 'combo',
      comboType: 'ActivityType',
    },
    {
      dataIndex: 'AttachmentURL',
      header: 'AttachmentURL',
    },
    {
      dataIndex: 'Title',
      header: 'Title',
    },
    {
      dataIndex: 'Publisher',
      header: 'Publisher',
    },
    {
      dataIndex: 'Description',
      header: 'Description',
    },
    {
      dataIndex: 'UserId',
      header: 'UserId',
      type: 'combo',
      comboType: 'User',
    },
    {
      dataIndex: 'URL',
      header: 'URL',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'ActivityTypeId',
      header: 'ActivityTypeId',
      type: 'combo',
      comboType: 'ActivityType',
    },
    {
      dataIndex: 'AttachmentURL',
      header: 'AttachmentURL',
    },
    {
      dataIndex: 'Title',
      header: 'Title',
    },
    {
      dataIndex: 'Publisher',
      header: 'Publisher',
    },
    {
      dataIndex: 'Description',
      header: 'Description',
    },
    {
      dataIndex: 'URL',
      header: 'URL',
      isRequired: true,
    },
    {
      dataIndex: 'Hide',
      header: 'Hide',
      type: 'boolean',
    },
  ],
  comboTypes: [
    {
      type: 'ActivityType',
      ValueField: 'ActivityTypeName',
      IDField: 'ActivityTypeId',
    },
  ],
  childs: () => [],
};
