import React, { Component } from 'react';
import {
  Card,
  List,
  ListItemAvatar,
  ListItem,
  Avatar,
  Typography,
  CardActionArea,
  ListItemText,
} from '@material-ui/core';
class VolunteerCard extends Component {
  render() {
    return (
      <Card
        style={{
          padding: '0px 10px',
          backgroundColor: this.props.bgColor,
          borderRadius: '15px',
        }}
      >
        <CardActionArea>
          <List>
            <ListItem alignItems="flex-start">
              <img
                src="https://www.w3schools.com/howto/img_avatar.png"
                height="60"
                style={{ borderRadius: '5px', margin: '5px' }}
              />

              <ListItemText
                primary={
                  <React.Fragment>
                    Dinesh
                    <Typography
                      variant="body2"
                      style={{ color: 'blue', marginTop: '5px' }}
                      component="p"
                    >
                      5 minutes ago
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </CardActionArea>
      </Card>
    );
  }
}

export default VolunteerCard;
