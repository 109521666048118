import { Actions } from '../core/redux-helper';

export const UserRole = {
  key: 'UserRole',
  title: 'UserRole',
  idColumn: 'UserRoleId',
  listAPI: Actions.listUserRole,
  getGridColumns: () => [
    {
      dataIndex: 'UserRoleId',
      header: 'UserRoleId',
    },
    {
      dataIndex: 'UserRoleName',
      header: 'UserRoleName',
    },
    {
      dataIndex: 'CreatedDate',
      header: 'CreatedDate',
      type: 'date',
    },
    {
      dataIndex: 'ModifiedDate',
      header: 'ModifiedDate',
      type: 'date',
    },
  ],
  getFormColumns: () => [
    {
      dataIndex: 'UserRoleName',
      header: 'UserRoleName',
      isRequired: true,
    },
  ],
  comboTypes: [],
  childs: () => [],
};
