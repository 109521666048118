import { Actions } from '../core/redux-helper';
import { defaultGridColumns } from '../core/utils';

export const SurveyQuestion = {
  key: 'SurveyQuestion',
  title: 'SurveyQuestion',
  idColumn: 'SurveyQuestionId',
  listAPI: Actions.listSurveyQuestion,
  getGridColumns: () => [
    {
      dataIndex: 'SurveyQuestionId',
      header: 'Survey Question',
    },
    {
      dataIndex: 'SurveyId',
      header: 'Survey',
      type: 'combo',
      comboType: 'SurveyId',
    },
    {
      dataIndex: 'SurveyQuestionTypeId',
      header: 'Survey Question Type',
      type: 'combo',
      comboType: 'SurveyQuestionTypeId',
    },
    {
      dataIndex: 'Question',
      header: 'Question',
    },
    {
      dataIndex: 'Options',
      header: 'Options',
    },
    {
      dataIndex: 'SurveyTagIds',
      header: 'SurveyTagIds',
    },

    ...defaultGridColumns(),
  ],
  getFormColumns: () => [
    {
      dataIndex: 'SurveyId',
      header: 'Survey',
      type: 'combo',
      comboType: 'SurveyId',
    },
    {
      dataIndex: 'SurveyQuestionTypeId',
      header: 'Survey Question Type',
      type: 'combo',
      comboType: 'SurveyQuestionTypeId',
    },
    {
      dataIndex: 'Question',
      header: 'Question',
    },
    {
      dataIndex: 'Options',
      header: 'Options',
    },
    {
      dataIndex: 'SurveyTagIds',
      header: 'SurveyTagIds',
    },
  ],
  childs: () => [],
  comboTypes: [
    {
      type: 'SurveyId',
      ValueField: 'Name',
      IDField: 'SurveyId',
    },
    {
      type: 'SurveyQuestionTypeId',
      ValueField: 'Name',
      IDField: 'SurveyQuestionTypeId',
    },
  ],
};
