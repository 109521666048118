import React, { PureComponent } from 'react';
import { ActivityType } from '../entityconfig';
import BaseView from './BaseView';

class ActivityTypeView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(ActivityType);
  }
}

export default ActivityTypeView;
