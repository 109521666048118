import React, { Component } from 'react';
import Button from 'components/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

export class Suggestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alignment: '',
      toggleButton: 'Risks',
    };
  }

  ToggleButtonGroup = () => {
    const { t } = this.props;
    const toggleButton = [
      {
        title: t('Risks'),
        filter: () => this.props.filter('Risks'),
      },
      {
        title: t('Controls'),
        filter: () => this.props.filter('Controls'),
      },
      {
        title: t('Control - Sugar'),
        filter: () => {},
      },
      {
        title: t('Risk - Fever'),
        filter: () => {},
      },
      {
        title: t('Risk - Mosquito'),
        filter: () => {},
      },
    ];
    return toggleButton.map(item => {
      return (
        <span style={{ marginRight: 10 }}>
          <Button
            className="toggleButton"
            style={{
              backgroundColor: this.state.toggleButton === item.title ? '#40189F' : '#E4D7FF',
              color: this.state.toggleButton === item.title ? '#fff' : '#40189F',
            }}
            onClick={() => {
              this.highlightButton(item.title);
              item.filter();
            }}
          >
            {item.title}
          </Button>
        </span>
      );
    });
  };

  highlightButton = buttonLabel => {
    this.setState({
      toggleButton: buttonLabel,
    });
  };

  handleAlignment = newAlignment => {
    this.setState({ alignment: newAlignment });
  };

  render() {
    return (
      <div className="suggestionsContainer">
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={2}>
            <Typography variant="body2">Suggestions</Typography>
          </Grid>

          <Grid item xs={8}>
            {this.ToggleButtonGroup()}
          </Grid>
          <Grid container xs={2} justifyContent="flex-end">
            {this.props.add()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(Suggestions);
