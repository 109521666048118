import React, { PureComponent } from 'react';
import { User } from '../entityconfig';
import BaseView from './BaseView';

class UserView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(User);
  }
}

export default UserView;
