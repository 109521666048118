import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions, injectTOStore } from '../core/redux-helper';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { GoogleLogin } from '@react-oauth/google';
import {
  Activity,
  ActivityType,
  AttachmentType,
  City,
  Product,
  EntityMaster,
  EntityDetail,
  Menu,
  Policy,
  PolicyCoverage,
  InsuranceProduct,
  LookUpType,
  LookUp,
  Party,
  Person,
  Transaction,
  Address,
  Billing,
  SecurityModule,
  Comment,
  ControlRoomRequest,
  ControlRoomRequestReply,
  Logs,
  News,
  Survey,
  SurveyQuestion,
  SurveyDetail,
  ToDo,
  User,
  UserRole,
  WellnessCenter,
  WellnessCenterType,
} from '../entityconfig';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

import { Icon as AntdIcon } from 'antd';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/LoginInput/LoginInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// import Card from 'components/Card/Card.jsx';
// import CardBody from 'components/Card/CardBody.jsx';
// import CardHeader from 'components/Card/CardHeader.jsx';
// import CardFooter from 'components/Card/CardFooter.jsx';
// import Pagination from 'components/Pagination/Pagination.jsx';
import { Checkbox, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { red } from '@material-ui/core/colors';
import Snackbar from 'components/Snackbar/Snackbar.jsx';

import { Modal } from 'antd';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import { Input } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import logo from '../assets/img/LogoLogin.png';
import CardMedia from '@material-ui/core/CardMedia';

// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { disableRippleEffect } from '../app-config/index';
import { withTranslation } from 'react-i18next';

import { newConfig } from 'store/config';
const modal = Modal;
const RedCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },

  checked: {},
})(props => <Checkbox disableRipple={disableRippleEffect} color="primary" {...props} />);

class Login extends Component {
  constructor(props) {
    super(props);
    // var d = new Date();
    this.state = {
      username: '',
      password: '',
      recoverUsername: '',
      recoverEmail: '',
      errorMessage: '',
      checked: false,
      // modal: '',
      // content: '',
      // year: d.getFullYear(),
      cardAnimaton: 'cardHidden',
      modelOpen: false,
      isShowModal: true,
      logoText: 'scouter',
    };

    this.timeZoneMapping = {
      AST: 'Atlantic Standard Time',
      ADT: 'Atlantic Daylight Time',
      EDT: 'Eastern Daylight Time',
      EST: 'Eastern Standard Time',
      CST: 'Central Standard Time',
      CDT: 'Central Daylight Time',
      MST: 'Mountain Standard Time',
      MDT: 'Mountain Daylight Time',
      PST: 'Pacific Standard Time',
      PDT: 'Pacific Daylight Time',
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getTimeZone = () => {
    var d = new Date().toString();
    var i = d.indexOf('(');
    var timeZone;
    if (i > -1) {
      timeZone = d.substr(i + 1, d.length - i - 2);
    } else {
      // IE
      var parts = d.split(' ');
      timeZone = this.timeZoneMapping[parts[parts.length - 2]];
    }
    return timeZone;
  };
  progress = () => {
    const { t } = this.props;
    modal.destroyAll();
    modal.error({
      icon: <AntdIcon type="loading" style={{ fontSize: 24, marginRight: '80px' }} spin />,
      title: t('Please wait') + '....',
      mask: true,
      okButtonProps: { style: { display: 'none' } },
    });
  };

  generateConfig(items) {
    var mapper = [];
    var gridMappings = [];
    var formMappings = [];

    for (var item of items) {
      var gridColumns = item.getGridColumns();
      var formColumns = item.getFormColumns();
      var columnMapper = {};
      for (var col of gridColumns) {
        columnMapper[col.dataIndex] = { ...col, hideInForm: true };
        delete columnMapper[col.dataIndex].comboType;
        delete columnMapper[col.dataIndex].type;
      }
      for (var col of formColumns) {
        if (!columnMapper[col.dataIndex]) {
          columnMapper[col.dataIndex] = { hideInGrid: true };
        }
        delete columnMapper[col.dataIndex].hideInForm;
        let { dataIndex, header, ...rest } = col;
        columnMapper[col.dataIndex].editor = { ...rest };
      }
      for (var columnKey in columnMapper) {
        for (var key in columnMapper[columnKey]) {
          if (!(gridMappings.indexOf(key) >= 0)) {
            gridMappings.push(key);
          }
        }
        for (var key in columnMapper[columnKey].editor) {
          if (!(formMappings.indexOf(key) >= 0)) {
            formMappings.push(key);
          }
        }
      }

      var config = {
        entity: item.key,
        title: item.title,
        details: [],
      };
      for (var key in columnMapper) {
        config.details.push(columnMapper[key]);
      }

      mapper.push(config);
    }
    console.clear();
    console.log(JSON.stringify(mapper));
    console.clear();
  }

  buttonClick = () => {
    const { t } = this.props;
    /*this.generateConfig([ Activity,
      ActivityType,
      AttachmentType,
      City,
      Product,
      EntityMaster,
      EntityDetail,
      Menu,
      Policy,
      PolicyCoverage,
      InsuranceProduct,
      LookUpType,
      LookUp,
      Party,
      Person,
      Transaction,
      Address,
      Billing,
      SecurityModule,
      Comment,
      ControlRoomRequest,
      ControlRoomRequestReply,
      Logs,
      News,
      Survey,
      SurveyQuestion,
      SurveyDetail,
      ToDo,
      User,
      UserRole,
      WellnessCenter,
      WellnessCenterType,]);*/

    const { username, password, checked, isShowModal } = this.state;
    //snackBarVisible
    //this.props.history.push('/home');

    if (username && password) {
      localStorage.setItem('username', username);
      var params = {
        email: username,
        password: password,
        language: 'English',
        identifier: 'login',
        timezone: this.getTimeZone(),
      };

      if (checked) {
        params['RememberMe'] = 'on';
      }
      this.props.dispatch(Actions.login(params));
      if (isShowModal) {
        this.progress();
      }
    } else if (!username && !password) {
      this.setState(
        {
          message: t('Please enter Username and Password'),
          snackBarVisible: true,
          color: 'danger',
        },
        () => setTimeout(() => this.setState({ snackBarVisible: false }), 5000),
      );
    } else if (!username) {
      this.setState(
        { message: t('Please Enter the Username'), snackBarVisible: true, color: 'danger' },
        () => setTimeout(() => this.setState({ snackBarVisible: false }), 3000),
      );
    } else if (!password) {
      this.setState(
        { message: t('Please Enter the Password'), snackBarVisible: true, color: 'danger' },
        () => setTimeout(() => this.setState({ snackBarVisible: false }), 3000),
      );
    }
    // else {
    //   this.setState({ message: 'Please Enter all the credentials', snackBarVisible: true, color: 'danger' }, () =>
    //     setTimeout(() => this.setState({ snackBarVisible: false }), 1000))
    // }
  };

  componentWillReceiveProps(nextProps) {
    const {
      isSuccess,
      errorMessage,
      access_token,
      expires_in,
      refresh_token,
      showErrorMessage,
      resetPasswordSuccess,
      resetPasswordInfo,
      menu,
      masterDataList,
      dynamicConfig,
      userTable,
      localizedData,
      organizationId,
    } = nextProps;

    if (isSuccess) {
      this.setState({
        isShowModal: false,
      });
      modal.destroyAll();
    }

    if (resetPasswordInfo) {
      if (resetPasswordSuccess) {
        this.setState(
          {
            modelOpen: false,
            message: resetPasswordInfo,
            snackBarVisible: true,
            color: 'success',
            recoverEmail: '',
            recoverUsername: '',
          },
          () =>
            setTimeout(() => {
              this.props.dispatch(Actions.resetRecoverPassword({ identifier: 'RecoverPassword' }));
              this.setState({ snackBarVisible: false, message: '' });
            }, 5000),
        );
      } else {
        this.setState(
          {
            message: resetPasswordInfo,
            snackBarVisible: true,
            color: 'danger',
            recoverEmail: '',
            recoverUsername: '',
          },
          () =>
            setTimeout(() => {
              this.props.dispatch(Actions.resetRecoverPassword({ identifier: 'RecoverPassword' }));
              this.setState({ snackBarVisible: false, message: '' });
            }, 5000),
        );
      }
    } else if (showErrorMessage && errorMessage) {
      this.setState(
        { message: errorMessage, snackBarVisible: true, color: 'danger', isShowModal: false },
        () => modal.destroyAll(),
        setTimeout(() => {
          this.props.dispatch(Actions.resetLogin({ identifier: 'Login' }));
          this.setState({ snackBarVisible: false, message: '' });
        }, 1000),
      );
    }
    if (isSuccess && isSuccess != this.props.isSuccess) {
      var token = {
        //expires_in: expires_in,
        access_token: access_token,
        //refresh_token: refresh_token,
        created: Date.now(),
      };
      localStorage.setItem('cube:token', JSON.stringify(token));
      localStorage.setItem('menu', JSON.stringify(menu));
      var entityMapping = {};
      for (var item of masterDataList) {
        entityMapping[item.table] = item;
      }
      injectTOStore(newConfig);
      localStorage.setItem('entityMapping', JSON.stringify(entityMapping));
      localStorage.setItem('dynamicConfig', JSON.stringify(dynamicConfig));
      localStorage.setItem('userTable', userTable);
      localStorage.setItem('languageData', JSON.stringify(localizedData));
      localStorage.setItem('organizationId', organizationId);
      
      sessionStorage.setItem('fullAccess', '1');
      this.props.history.push('/Dashboard');
      window.location.reload();
      /*var data = deviceDetect();
      data.timeZoneOffSet = new Date().getTimezoneOffset();
      data.language = window.navigator.userLanguage || window.navigator.language;
      data.token = access_token;
      data.refreshToken = refresh_token;

      this.props.dispatch(logDevice(data));

      this.setState({
        modal: true,
        content: (
          <div>
            You are about to authorize a secure system
            <br /> Are you sure you want to proceed?
            <br />
            <br />
            <TextArea
              placeholder="You are accessing an authorised system"
              autosize={{ minRows: 4, maxRows: 6 }}
              style={{ width: '100%' }}
            />
          </div>
        ),
      });*/
    }
    if (errorMessage) {
      /*var data = deviceDetect();
      data.timeZoneOffSet = new Date().getTimezoneOffset();
      data.language = window.navigator.userLanguage || window.navigator.language;
      */

      //this.props.dispatch(logDevice(data));
      this.setState({ errorMessage: errorMessage });
    }
  }

  enterButton = event => {
    if (event.key == 'Enter') {
      this.buttonClick();
    }
  };
  okMethod = () => {
    //this.props.dispatch(loadMenu({}));
    var param = new URLSearchParams(this.props.history.location.search);
    var redirectPath = param.get('redirectPath');
    if (redirectPath) {
      this.props.history.push(redirectPath);
      //window.location.href = '/' + redirectPath;
    } else {
      this.props.history.push('/home');
    }
  };
  cancelMethod = () => {
    localStorage.clear();
    window.location.reload();
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700,
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange = event => this.setState({ checked: event.target.checked });

  handleOpen = () => {
    this.setState({
      modelOpen: true,
    });
  };
  handleClose = () => {
    this.setState({
      modelOpen: false,
    });
  };

  resetPassword = () => {
    const { t } = this.props;
    const { recoverEmail, recoverUsername } = this.state;
    if (recoverUsername || recoverEmail) {
      this.props.dispatch(
        Actions.recoverPassword({
          username: this.state.recoverUsername,
          email: this.state.recoverEmail,
          identifier: 'RecoverPassword',
          action: 'recoverpassword',
        }),
      );
    } else if (!recoverUsername && !recoverEmail) {
      this.setState(
        { message: t('You must enter username or email'), snackBarVisible: true, color: 'danger' },
        () => setTimeout(() => this.setState({ snackBarVisible: false }), 5000),
      );
    }
  };

  render() {
    const { classes, t } = this.props;
    const {
      username,
      password,
      snackBarVisible,
      color,
      message,
      modelOpen,
      recoverUsername,
      recoverEmail,
      logoText,
    } = this.state;
    return (
      <div className={classes.container}>
        <Snackbar place="tc" color={color} message={message} open={snackBarVisible} />
        <div style={{ marginTop: '10%' }}></div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={5}>
            <form>
              <Card
                style={{
                  position: 'relative',
                  boxShadow: '4px 19px 25px -5px rgba(0,0,0,0.18)',
                  padding: '15px',
                  paddingTop: '80px',
                }}
              >
                <CardMedia
                  component="div"
                  style={{
                    position: 'absolute',
                    top: '10%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  {/* <img src={logo} style={{ width: '30%' }} /> */}
                  <span
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: '900',
                      // fontSize: 36 + 'px !important',
                      fontSize: '36px',
                      textShadow: '2px 2px 4px #FF0000',
                      display: 'block',
                      padding: '4px 0px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {t('Admin Portal')}
                  </span>
                </CardMedia>
                <CardContent>
                  <CustomInput
                    labelText={t('Username')}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: username,
                      onChange: event => this.setState({ username: event.target.value }),
                    }}
                  />
                  <CustomInput
                    labelText={t('Password')}
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    type="password"
                    inputProps={{
                      value: password,
                      onChange: event => this.setState({ password: event.target.value }),
                      onKeyPress: this.enterButton,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <RedCheckbox checked={this.state.checked} onChange={this.handleChange} />
                    }
                    label={t('Keep Me Logged In')}
                  />
                </CardContent>
                <CardActions>
                  <Grid item xs={6}>
                    <Button
                      autofocus="true"
                      className={classes.margin}
                      onClick={this.buttonClick}
                      style={{
                        width: '100%',
                        fontSize: '14px',
                        backgroundColor: '#24b5ee',
                        color: 'white',
                        height: '50px',
                        borderRadius: '55px',
                      }}
                    >
                      {t('Login')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      tabindex="-1"
                      className={classes.margin}
                      onClick={this.handleOpen}
                      style={{
                        width: '100%',
                        fontSize: '14px',
                        backgroundColor: '#fff',
                        border: '2px #24b5ee solid',
                        color: '#24b5ee',
                        height: '50px',
                        borderRadius: '55px',
                      }}
                    >
                      {t('Forgot Password')}
                    </Button>
                    <Dialog
                      open={modelOpen}
                      onClose={this.handleClose}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogTitle id="form-dialog-title">{t('Forgot Password')}</DialogTitle>
                      <DialogContent>
                        <DialogContentText color="error">
                          {t('Please enter either Username or Email')}
                        </DialogContentText>
                        <CustomInput
                          labelText={t('Username')}
                          id="forgotUsername"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: recoverUsername,
                            onChange: event =>
                              this.setState({ recoverUsername: event.target.value }),
                          }}
                        />
                        <CustomInput
                          labelText={t('E-mail')}
                          id="forgotEmail"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: recoverEmail,
                            onChange: event => this.setState({ recoverEmail: event.target.value }),
                          }}
                        />
                      </DialogContent>
                      <DialogActions style={{ padding: '20px', paddingRight: '28px' }}>
                        <Button
                          onClick={this.resetPassword}
                          className={classes.margin}
                          style={{
                            backgroundColor: '#24b5ee',
                            color: 'white',
                            height: '50px',
                            borderRadius: '55px',
                            fontSize: '14px',
                            width: '30%',
                          }}
                        >
                          {t('OK')}
                        </Button>
                        <Button
                          onClick={this.handleClose}
                          className={classes.margin}
                          style={{
                            backgroundColor: 'inherit',
                            border: '2px #24b5ee solid',
                            color: '#24b5ee',
                            height: '50px',
                            borderRadius: '55px',
                            fontSize: '14px',
                            width: '30%',
                          }}
                        >
                          {t('Cancel')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </CardActions>

                <div>
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      console.log(credentialResponse);
                      this.setState({
                        username: 'admin@nofever.com',
                        password: 'Admin@123',
                      });
                      this.buttonClick();
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </div>
              </Card>

              {/* </Card> */}
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  render2() {
    const { errorMessage, isFetching, t } = this.props;
    return (
      <div className="App">
        <div class="container">
          <div class="Login">
            <div>
              <br />
              <br />
              <br />
              <div class="wrapper fadeInDown">
                <div id="formContent">
                  <div class="fadeIn first">
                    <img src={'loginUser.jpg'} id="icon" alt="User Icon" />
                    <h1>{t('Login Page')}</h1>
                  </div>
                  <form class="login-form">
                    <div bssize="large" class="form-group">
                      <label class="form-label" for="email">
                        {t('Email address')}
                      </label>
                      {/* <input type="email" id="email" class="fadeIn second form-control" value="" /> */}
                      <input
                        type="text"
                        class="fadeIn second form-control"
                        placeholder={t('Username/Email Address')}
                        onChange={event => (this.state.username = event.target.value)}
                      />
                    </div>
                    <div bssize="large" class="form-group">
                      <label class="form-label" for="password">
                        {t('Password')}
                      </label>
                      {/* <input
                      type="password"
                      id="password"
                      class="fadeIn third form-control"
                      value=""
                    /> */}
                      <input
                        type="password"
                        class="fadeIn third form-control"
                        placeholder={t('Password')}
                        onKeyPress={this.enterButton}
                        onChange={event => (this.state.password = event.target.value)}
                      />
                    </div>
                    <input
                      type="button"
                      value="Login"
                      class="fadeIn fourth btn btn-primary"
                      onClick={this.buttonClick}
                    />
                  </form>
                  {/* <CustomModal
                    isOpen={this.state.modal}
                    header={this.state.content}
                    yesAction={this.okMethod}
                    yesText="OK"
                    noText="Close"
                    noAction={this.cancelMethod}
                  />
                  {errorMessage && (
                    <div className="alert alert-warning mar-top-20" role="alert">
                      {errorMessage}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ login, recoverpassword }) => {
  const { data, isFetching, error } = login;
  var isSucess = false;
  var errorMessage = '';
  var access_token = '';
  var expires_in = '';
  var refresh_token = '';
  var menu = {};
  var organizationId = '';
  var localizedData = [];
  var masterDataList = [];
  var dynamicConfig = [];
  let userTable = null;
  var modules = {};
  var showErrorMessage = false;
  var resetPasswordInfo = '';
  var resetPasswordSuccess = '';

  if (recoverpassword && recoverpassword.data) {
    resetPasswordInfo = recoverpassword.data.info;
    resetPasswordSuccess = recoverpassword.data.success;
  }

  if (data) {
    if (data.access_token) {
      isSucess = true;
      access_token = data.access_token;
      expires_in = data.expires_in;
      refresh_token = data.refresh_token;
    } else if (data.token) {
      isSucess = true;
      menu = data.menu;
      masterDataList = JSON.parse(data.masterDataList);
      dynamicConfig = JSON.parse(data.dynamicConfig);
      userTable = data.userTable;
      localizedData = data.localizedData;
      organizationId = data.organizationId;
      modules = data.modules;
      access_token = data.token;
    } else {
      errorMessage = data.message;
      showErrorMessage = true;
    }
  } else if (error) {
    errorMessage = error.error_description;
    showErrorMessage = true;
  }

  return {
    resetPasswordInfo,
    resetPasswordSuccess,
    menu,
    masterDataList,
    dynamicConfig,
    userTable,
    localizedData,
    organizationId,
    modules,
    showErrorMessage,
    isSuccess: isSucess,
    errorMessage: errorMessage,
    access_token: access_token,
    expires_in: expires_in,
    refresh_token: refresh_token,
    isFetching: isFetching,
  };
};

export default withTranslation()(connect(mapStateToProps)(withStyles(loginPageStyle)(Login)));
