import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import { List, Card, Select } from 'antd';
import { connect } from 'react-redux';
import { Actions } from 'core/redux-helper';
import axios from 'axios';
import { URL, APIVersion } from 'app-config';
import CustomSelect from 'components/CustomSelect/Select';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const { Option } = Select;

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
};

let processCount = 0;
class CsvPlaceexrac extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadingIndex: null,
      loadingCities: true,
      CityId: '6f7c219b-3699-4704-9ab8-6d6a20003c34',
      Country: 'US',
      message: {},
      IpAddress: [],
      errArray: [],
      BatchName: '',
      showRestartButton: false,
      succeededCsvData: {},
      restartCount: 0,
    };
    props.dispatch(
      Actions.listCity({
        action: 'list',
        identifier: 'city',
        comboTypes: [{ type: 'City', ValueField: 'CityName' }],
      }),
    );
  }

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  handleForce = data => {
    const result = data.map((item, index) => {
      return {
        ...item,
        id: index,
      };
    });
    this.setState({
      data: result,
      BatchName: 'b-' + moment().format('DD-MM-YYYY-HH:mm'),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { restartCount } = this.state;
    if (prevState.restartCount !== restartCount) {
      this.startProcess();
    }
  }

  startProcess = async () => {
    const {
      data,
      Country,
      CityId,
      IpAddress,
      BatchName,
      succeededCsvData,
      restartCount,
    } = this.state;
    if (data.length == 0) {
      alert('load Place Data');
      return;
    }
    if (IpAddress.length == 0) {
      alert('Input Ip Data');
      return;
    }
    this.setState({ loadingIndex: 0 });
    for (let i = 0; i < data.length; i++) {
      const { name, address, placetype, instagram_location_id, instagram_handle, id } = data[i];
      if (restartCount !== processCount) {
        this.setState({
          restartCount: processCount,
        });
        console.log('breaked');
        break;
      }
      if (!succeededCsvData[id] || succeededCsvData[id] !== 'completed') {
        var GooglePlaceName = name + ' ' + address;
        GooglePlaceName = GooglePlaceName.replace(/,/g, ' ')
          .replace(/[^\x00-\x7F]/g, '')
          .replace("'", '');
        var int = parseInt(i % IpAddress.length);
        var param = {
          Country,
          GooglePlaceName,
          CityId,
          PlaceType: placetype,
          IpAddress: IpAddress[int].proxy,
          BatchName,
          InstagramHandle: instagram_handle ? 'https://www.instagram.com/' + instagram_handle : '',
          InstagramLocation: instagram_location_id
            ? 'https://www.instagram.com/explore/locations/' + instagram_location_id
            : '',
          HardInsert: true,
        };
        const formData = new FormData();
        for (let property in param) {
          formData.append(property, param[property]);
        }
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        console.log('Index ' + i);
        console.log('-----------');
        await axios
          .post(URL + APIVersion + 'Place/MultiPartInsert', formData, config)
          .then(async resp => {
            if (resp.data.success) {
              this.setState(
                {
                  message: {
                    ...this.state.message,
                    [`${i}`]: 'Success',
                  },
                  succeededCsvData: {
                    ...this.state.succeededCsvData,
                    [`${id}`]: 'completed',
                  },
                  loadingIndex: this.state.loadingIndex + 1,
                },
                () => {
                  const result = succeededCsvData;
                },
              );
            }
          })
          .catch(error => {
            this.setState({
              message: {
                ...this.state.message,
                [`${i}`]: error.response.data.Message,
              },
              loadingIndex: this.state.loadingIndex + 1,
              showRestartButton: true,
            });
            if (error.response.data.Message !== 'GooglePlaceName Already Exists') {
              this.setState({
                errArray: [
                  ...this.state.errArray,
                  { ...data[i], error: error.response.data.Message },
                ],
              });
            }
          });
      }
    }
  };

  setCountry = ({ target: { value } }) => {
    this.setState({ Country: value });
  };

  getIp = IpAddress => {
    this.setState({ IpAddress });
  };

  setCityId = value => {
    this.setState({ CityId: value });
  };

  render() {
    const {
      data,
      loadingIndex,
      Country,
      CityId,
      message,
      errArray,
      BatchName,
      showRestartButton,
    } = this.state;
    const { city } = this.props;

    return (
      <>
        <CSVReader
          cssClass="react-csv-input"
          label="Choose the file to extract places"
          onFileLoaded={this.handleForce}
          parserOptions={papaparseOptions}
        />
        <CSVReader
          cssClass="react-csv-input"
          label="Select Level 1 proxies"
          onFileLoaded={this.getIp}
          parserOptions={papaparseOptions}
        />
        Country: <input Value={Country} onChange={this.setCountry} />
        <br />
        city:
        {city.length == 0 ? (
          'loading cities...'
        ) : (
          <CustomSelect
            style={{ width: 200 }}
            showSearch
            value={CityId}
            onChange={this.setCityId}
            filterOption={(input, option) => {
              if (!option.props.children) {
                return false;
              }
              return input
                ? option.props.children.toLowerCase().startsWith(input.toLowerCase())
                : true;
            }}
          >
            {city.map(item => (
              <Option value={item.LookupId}>{item.DisplayValue}</Option>
            ))}
          </CustomSelect>
        )}
        <button onClick={this.startProcess}>start Process</button>
        {showRestartButton ? (
          <button
            style={{ marginLeft: 5 }}
            onClick={() => {
              processCount = processCount + 1;
            }}
          >
            Restart
          </button>
        ) : null}
        <br />
        BatchName: {BatchName}
        <br />
        <CSVLink data={errArray} separator={','}>
          download error after all the process is completed
        </CSVLink>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <Card style={{ width: 250, height: 200 }}>
                Name: {item.name}
                <br />
                Address: {item.address}
                <br />
                Placetype: {item.placetype}
                <br />
                {loadingIndex == index && 'loading....'}
                <br />
                Message: {message[index]}
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ({ combos }) => {
    debugger
  let city = [];
  if (combos) {
    city = combos.City || [];
  }
  return {
    city,
  };
};

export default connect(mapStateToProps)(CsvPlaceexrac);
