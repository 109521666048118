import React, { PureComponent } from 'react';
import { Product } from '../entityconfig';
import BaseView from './BaseView';

class ProductView extends BaseView {
  constructor(props) {
    super({ props });
    this.config = this.constructConfig(Product);
  }
}

export default ProductView;
