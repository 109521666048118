import React from 'react';
import { Chat, addResponseMessage, addLinkSnippet, addUserMessage } from 'react-chat-popup';

class ChatBox extends React.Component {
  componentDidMount() {
    addResponseMessage('How can I help you today!');
  }

  handleNewUserMessage = newMessage => {
    console.log(`New message incomig! ${newMessage}`);
    // Now send the message throught the backend API
  };

  render() {
    return (
      <div className="App">
        <Chat handleNewUserMessage={this.handleNewUserMessage} title="Maiden Cube Pvt Ltd" />
      </div>
    );
  }
}

export default ChatBox;
